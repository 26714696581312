import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { Button, Popconfirm } from "antd";
import {
  CheckOutlined,
  ControlOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";

type TranscriptionJob = {
  id: string;
  dateCreated: number;
  fileName: string;
  isDeleted: boolean;
  type: string;
  url: string;
  isComplete?: boolean;
};

export const Transcribe = () => {
  const [jobList, setJobList] = useState<Array<TranscriptionJob>>([]);
  const nav = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "uploads"), (snapshot) => {
      setJobList([]);
      snapshot.docs.forEach((doc) => {
        setJobList((prev) => [
          ...prev,
          { ...doc.data(), id: doc.id } as TranscriptionJob,
        ]);
      });
    });
    return unsub;
  }, []);

  const download = (url: string) => {
    // const a = document.createElement("a");
    getDownloadURL(ref(storage, url))
      .then((url) => {
        window.open(url, "_blank");
        // a.href = url;
        // a.download = url;
        // a.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgba(30,30,30,.8)",
      }}
    >
      <div
        style={{
          width: "100vw",
          //height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          className=" text-center self-center p-8"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "500px",
            width: "90%",
            maxHeight: "100vh",
            minWidth: "300px",
            backgroundColor: "rgba(40,40,40,.8)",
            borderRadius: ".25rem",
            alignSelf: "center",
            overflowY: "auto",
            overflowX: "hidden",
            boxShadow: "0px 5px 20px 20px rgb(20,20,20)",
          }}
        >
          <h1
            style={{
              color: "rgb(160,160,160)",
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            Transcription Jobs
          </h1>
          {jobList
            .filter((job) => !job.isDeleted)
            .map((job) => (
              <div
                key={job.id}
                style={{
                  marginBottom: "1rem",
                  width: "100%",
                  maxWidth: "500px",
                  padding: "1rem",
                  border: "1px solid rgb(30,30,30)",
                  borderRadius: ".25rem",
                  boxShadow: "1px 3px 3px 3px rgba(20,20,20,.4)",
                  backgroundColor: job.isComplete
                    ? "rgb(40,40,40)"
                    : "rgb(60,40,40)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ color: "rgb(160,160,160)", fontWeight: "bold" }}
                  >
                    {job.fileName}
                  </div>
                  <div></div>
                </div>
                <div>
                  {new Date(job.dateCreated).toLocaleDateString("en-GB")}
                </div>
                <p style={{ color: "rgb(130,130,130)" }}>{job.url}</p>
                {!job.isDeleted && (
                  <Popconfirm
                    title="Are you sure you want to delete this?"
                    onConfirm={() => {
                      updateDoc(doc(db, `uploads/${job.id}`), {
                        isDeleted: true,
                      });
                    }}
                  >
                    <Button
                      type="text"
                      icon={!job.isDeleted && <DeleteOutlined />}
                      danger
                    />
                  </Popconfirm>
                )}
                <Button
                  type="text"
                  icon={<DownloadOutlined style={{ color: "dodgerblue" }} />}
                  onClick={() => download(job.url)}
                />
                {!job.isComplete && (
                  <Popconfirm
                    title="Have you completed this?"
                    onConfirm={() => {
                      updateDoc(doc(db, `uploads/${job.id}`), {
                        isComplete: true,
                      });
                    }}
                  >
                    <Button
                      type="text"
                      icon={!job.isComplete && <CheckOutlined />}
                      danger
                    />
                  </Popconfirm>
                )}
              </div>
            ))}
          <Button
            type="text"
            style={{ fontFamily: "Oxanium", margin: ".5rem", color: "white" }}
            icon={<ControlOutlined />}
            onClick={() => nav("/tools")}
          />
        </div>
      </div>
    </div>
  );
};
