import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";
import { Button } from "antd";
import {
  BarsOutlined,
  FlagOutlined,
  PlayCircleFilled,
  StopOutlined,
} from "@ant-design/icons";

interface LineGraphProps {
  data: Uint8Array;
}

export const AudioGraph: React.FC<LineGraphProps> = ({ data }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const frameCount = useRef(1);
  const { width, height } = useRecoilValue(windowSize);
  const [warning, setWarning] = useState(false);
  const [redline, setRedline] = useState(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [colors, setColors] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<boolean>(false);
  const time = useRef(Date.now());
  const timeRedline = useRef(Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      if (Date.now() - timeRedline.current > 1000) {
        setRedline(false);
      }
      if (Date.now() - time.current > 2000) {
        setWarning(false);
      }

      if (canvasRef.current && isRecording) {
        const imageDataUrl = canvasRef.current.toDataURL("image/png");

        fetch("https://app.scryptworxstudios.com/save-image", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            imageDataUrl: imageDataUrl,
            frame: frameCount.current,
          }),
        })
          .then(() => frameCount.current++)
          .catch((error) => console.error("Error:", error));
      }
    }, 40);

    return () => clearInterval(timer);
  }, [isRecording]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");

      if (context) {
        const width1 = canvas.width;
        const height1 = canvas.height;

        // Clear the canvas
        context.clearRect(0, 0, width1, height1);

        // Set the stroke style
        context.strokeStyle = "whitesmoke";
        context.lineWidth = 1;

        // Draw the line graph
        context.beginPath();
        context.moveTo(0, height1);
        for (let i = 0; i < data.length; i++) {
          const x = (i / (data.length - 1)) * width1;
          const y = (1 - data[i] / 255) * height1;
          if (y > height * 0.9) {
            timeRedline.current = Date.now();
            setRedline(true);
          }
          if (y > height * 0.8) {
            time.current = Date.now();
            setWarning(true);
          }
          context.lineTo(x, y);
        }
        context.stroke();
      }
    }
  }, [data, canvasRef, width, height]);

  return (
    <div
      style={{ width: "100%", height: "100%", background: "rgba(0,0,0,0.8)" }}
    >
      <div style={{ position: "absolute", top: "0", zIndex: 20 }}>
        <Button
          type="text"
          onClick={() => setColors(!colors)}
          icon={<BarsOutlined style={{ color: colors ? "red" : "white" }} />}
        />
        <Button
          type="text"
          onClick={() => setWarnings(!warnings)}
          icon={<FlagOutlined style={{ color: warnings ? "red" : "white" }} />}
        />
        <Button
          type="text"
          onClick={() => setIsRecording(true)}
          icon={
            <PlayCircleFilled
              style={{ color: !isRecording ? "green" : "white" }}
            />
          }
        />
        <Button
          type="text"
          onClick={() => {
            setIsRecording(false);
            frameCount.current = 1;
          }}
          icon={
            <StopOutlined style={{ color: isRecording ? "red" : "white" }} />
          }
        />
      </div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: colors
            ? `linear-gradient(180deg, rgba(255,0,0,${
                warnings && redline ? "1" : "0.4"
              }) 0%, rgba(249,156,0,${
                warnings && warning ? "0.5" : "0.1"
              }) 7%, rgba(31,223,0,0) 31%, rgba(31,223,0,0) 69%,  rgba(249,156,0,${
                warnings && warning ? "0.5" : "0.1"
              }) 93%, rgba(255,0,0,${warnings && redline ? "1" : "0.4"}) 100%)`
            : "rgba(0,0,0,0.6)",
        }}
      >
        {data.length > 0 && (
          <canvas ref={canvasRef} width={width} height={height} />
        )}
      </div>
    </div>
  );
};
