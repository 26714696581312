import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const NoAccess = () => {
  const nav = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div className="bg-black bg-opacity-80 w-full h-full">
        <div className="verticleCenter" style={{ height: "100%" }}>
          <div
            className=" text-white font-semibold mb-6 mt-10 font-bold, text-2xl"
            style={{ fontFamily: "oxanium" }}
          >
            Access Permissions Required
          </div>
          <Button type="primary" onClick={() => nav("/")}>
            Home
          </Button>
        </div>
      </div>
    </div>
  );
};
