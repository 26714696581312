import {
  BookOutlined,
  ControlOutlined,
  EditOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Popover,
  Table,
} from "antd";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { FinanceEntryType } from "../typeDefs";
import { Finances } from "./Finances";
import { PrintFinances } from "./PrintFinances";

export const FinanceReports = () => {
  const nav = useNavigate();
  const [data, setData] = useState<Array<FinanceEntryType>>([]);
  const [summaryData, setSummaryData] = useState<Array<any>>([]);
  const [activeItem, setActiveItem] = useState<FinanceEntryType | null>(null);
  const { RangePicker } = DatePicker;
  const [dateRange, setDateRange] = useState<Array<number>>([
    new Date(`${new Date().getFullYear()}-04-01`).getTime(),
    new Date().getTime(),
  ]);
  const [isModal, setIsModal] = useState(false);
  const [balance, setBalance] = useState({ cashAssets: 0, liabilities: 0 });

  const [balanceForm] = Form.useForm();

  const processBalanceSheet = () => {
    balanceForm
      .validateFields()
      .then((values) => {
        setBalance(() => ({ ...values }));
      })
      .catch((err) => console.error(err));
  };

  const calculateStraightLineDepreciation = (
    purchaseDate: number,
    purchasePrice: number,
    depreciationRate: number
  ) => {
    console.log({ depreciationRate });
    const currentDate = new Date().getTime();
    const purchaseDateObj = purchaseDate;

    // Ensure the purchase date is valid and before the current date
    if (purchaseDateObj > currentDate) {
      throw new Error("Invalid purchase date.");
    }

    // Calculate the number of years since purchase
    const yearsSincePurchase =
      new Date(currentDate).getFullYear() -
      new Date(purchaseDateObj).getFullYear();

    if (purchasePrice < 1000) {
      return {
        yearsSincePurchase: yearsSincePurchase,
        annualDepreciation: 0,
        totalDepreciation: 0,
        currentValue: 0,
        writeOff: yearsSincePurchase < 1 ? true : false,
      };
    }

    // Depreciation per year
    const annualDepreciation = purchasePrice * (depreciationRate / 100);

    // Total depreciation to date
    const totalDepreciation = annualDepreciation * yearsSincePurchase;

    // Remaining value
    const currentValue = Math.max(0, purchasePrice - totalDepreciation);

    console.log({
      yearsSincePurchase,
      annualDepreciation,
      totalDepreciation,
      currentValue,
    });

    return {
      yearsSincePurchase,
      annualDepreciation,
      totalDepreciation,
      currentValue,
      writeOff: false,
    };
  };

  useEffect(() => {
    const unsub = onSnapshot(collection(db, "finances"), (snapshot) => {
      setData([]);
      snapshot.forEach((doc) => {
        setData((prev) => [
          ...prev,
          { ...doc.data(), id: doc.id } as FinanceEntryType,
        ]);
      });
    });

    return unsub;
  }, []);

  useEffect(() => {
    setSummaryData([]);
    let gstOut = 0;
    let gstIn = 0;
    let gstBalance = 0;
    let expenses = 0;
    let revenue = 0;
    let expensesSal = 0;
    let purchases = 0; // gst balance
    let assetBookValue = 0;

    data
      .filter((item) => !item.isDeleted)
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Revenue")
      .filter((item) => item.date >= new Date("2024-05-02").getTime())
      .filter((item) => item.gstNumber && item.gstNumber?.length > 5)
      .forEach((item) => {
        gstOut = gstOut + item.amount * 0.15;
      });

    data
      .filter((item) => !item.isDeleted)
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Assets")
      .filter((item) => item.date >= new Date("2024-05-02").getTime())
      .forEach((item) => {
        gstIn = gstIn + item.amount * 0.15;
      });

    data
      .filter((item) => !item.isDeleted)
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Expenses")
      .filter((item) => item.subCategory !== "Salaries and Wages")
      .forEach((item) => {
        expenses = expenses + item.amount;
      });

    data
      .filter((item) => !item.isDeleted)
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Expenses")
      .filter((item) => item.subCategory === "Salaries and Wages")
      .forEach((item) => {
        expensesSal = expensesSal + item.amount;
      });

    data
      .filter((item) => !item.isDeleted)
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Revenue")
      .forEach((item) => {
        revenue = revenue + item.amount;
      });

    data
      .filter((item) => !item.isDeleted)
      .filter((item) => item.date >= dateRange[0] && item.date < dateRange[1])
      .filter((item) => item.category === "Assets")
      .forEach((item) => {
        const calculatedDepreciation = calculateStraightLineDepreciation(
          item.date,
          item.amount,
          item.depreciationRate ? item.depreciationRate : 0
        );

        assetBookValue = assetBookValue + calculatedDepreciation.currentValue;
        purchases = purchases + item.amount;
      });

    gstBalance = gstIn - gstOut;
    let balanceResult =
      balance.cashAssets + assetBookValue - balance.liabilities;

    setSummaryData([
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Total revenue</div>
            <Popover content="All money entering into the business (INCLUDES GST)">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$${revenue.toFixed(2)}`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Expenses</div>
            <Popover content="Cost of expenses (not including salaries and asset purchases)">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${expenses.toFixed(2)})`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Asset purchases</div>
            <Popover content="Cost of purchasing assets">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${purchases.toFixed(2)})`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Salaries & Wages</div>
            <Popover content="Cost of salaries and wages">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${expensesSal.toFixed(2)})`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>
              Purchases + expenses + salaries
            </div>
            <Popover content="Cost of assets, expenses, personnel">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${(purchases + expenses + expensesSal).toFixed(2)})`,
      },

      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>GST to claim</div>
            <Popover content="GST on qualifing purchases (after GST registration 02/05/24)">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$${gstIn.toFixed(2)}`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>GST owing</div>
            <Popover content="GST on invoices sent (after GST registration 02/05/24)">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount: `$(${gstOut.toFixed(2)})`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>GST balance</div>
            <Popover content="GST to claim - GST owing">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount:
          gstBalance < 0
            ? `$(${Math.abs(gstBalance).toFixed(2)})`
            : `$${gstBalance.toFixed(2)}`,
      },
      {
        type: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "5px" }}>Equity</div>
            <Popover content="Assets - Liabilities = Equity">
              <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
            </Popover>
          </div>
        ),
        amount:
          balanceResult > 0
            ? `$${balanceResult.toFixed(2)}`
            : `$(${balanceResult.toFixed(2)})`,
      },
    ]);
  }, [data, dateRange, balance]);

  const summaryColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "GST Number",
      dataIndex: "gstNumber",
      key: "gstNumber",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const expensesColumns = [
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "GST Number",
      dataIndex: "gstNumber",
      key: "gstNumber",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const assetsColumns = [
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Category",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "GST Number",
      dataIndex: "gstNumber",
      key: "gstNumber",
    },
    {
      title: "GST",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const calculateBookValue = (
    originalValue: number,
    purchaseDate: number,
    depreciationRate: number
  ) => {
    // Parse the purchase date and calculate the number of years since purchase
    const purchaseYear = new Date(purchaseDate).getFullYear();
    const currentYear = new Date().getFullYear();
    const yearsElapsed = currentYear - purchaseYear;

    // Calculate the annual depreciation amount
    const annualDepreciation = (originalValue * depreciationRate) / 100;

    // Calculate the total depreciation over the elapsed years
    const totalDepreciation = annualDepreciation * yearsElapsed;

    // Calculate the book value
    const bookValue = originalValue - totalDepreciation;

    if (originalValue < 1000) return 0;

    // Ensure the book value doesn't go below zero
    return Math.max(bookValue, 0);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgb(230,230,230)",
        color: "rgb(200,200,200)",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "5rem",
          overflowY: "scroll",
        }}
      >
        <h1
          style={{
            padding: "1rem",
            color: "rgb(20,20,20)",
            width: "92%",
            textAlign: "left",
            fontFamily: "Oxanium",
            fontSize: "1.5rem",
          }}
        >
          Finance Reports
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <RangePicker
              onChange={(e) =>
                e &&
                setDateRange([
                  e[0]?.toDate().getTime() as number,
                  e[1]?.toDate().getTime() as number,
                ])
              }
            />
            <PrintFinances data={data} dates={dateRange} />
            <Popover content="Library of terms">
              <Button
                type="text"
                icon={<BookOutlined />}
                onClick={() => {
                  nav("/finance-library");
                }}
              />
            </Popover>
            <Button
              type="text"
              icon={<ControlOutlined />}
              onClick={() => nav("/tools")}
            />
          </div>
          <Button
            onClick={() => {
              setActiveItem(null);
              isModal ? setIsModal(false) : setIsModal(true);
            }}
          >
            New Entry
          </Button>
        </div>
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Revenue`}
              </div>
              <Popover content="Total money flow into the business">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter((item) => !item.isDeleted)
            .filter(
              (item) => item.date >= dateRange[0] && item.date < dateRange[1]
            )
            .filter((item) => item.category === "Revenue")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$${item.amount.toFixed(2)}`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : "-",
                key: item.id,
                edit: (
                  <Button
                    type="text"
                    icon={
                      <EditOutlined
                        style={{ color: "dodgerblue" }}
                        onClick={() => {
                          setActiveItem(item);
                          setIsModal(true);
                        }}
                      />
                    }
                  />
                ),
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;
            let gstTotal = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
              if (
                new Date(item.date).getTime() >=
                  new Date(`2024-05-02`).getTime() &&
                item.gstNumber &&
                item.gstNumber?.length > 5
              ) {
                gstTotal += item.amountNumber;
              }

              console.log(
                item.amountNumber,
                item.amountNumber * 0.15,
                gstTotal
              );
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total GST in: $${(gstTotal * 0.15).toFixed(
                2
              )}, Total revenue: $${total.toFixed(2)}`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Assets`}
              </div>
              <Popover content="Assets held in the business based on purchases made by the business">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={[
            {
              title: "",
              dataIndex: "edit",
              key: "edit",
            },
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
            {
              title: "Category",
              dataIndex: "subCategory",
              key: "subCategory",
            },
            {
              title: "Vendor",
              dataIndex: "vendor",
              key: "vendor",
            },
            {
              title: "GST Number",
              dataIndex: "gstNumber",
              key: "gstNumber",
            },
            {
              title: "GST Method",
              dataIndex: "method",
              key: "method",
            },
            {
              title: "GST Rate",
              dataIndex: "rate",
              key: "rate",
            },
            {
              title: "GST Claim",
              dataIndex: "claim",
              key: "claim",
            },
            {
              title: "PurchaseValue",
              dataIndex: "amount",
              key: "amount",
            },
            {
              title: "Depreciation",
              dataIndex: "depreciation",
              key: "depreciation",
            },
            {
              title: "Book Value",
              dataIndex: "bookValue",
              key: "bk",
            },
          ]}
          dataSource={data
            .filter((item) => !item.isDeleted)
            .filter(
              (item) => item.date >= dateRange[0] && item.date < dateRange[1]
            )
            .filter((item) => item.category === "Assets")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              const calculatedDepreciation = calculateStraightLineDepreciation(
                item.date,
                item.amount,
                item.depreciationRate ? item.depreciationRate : 0
              );

              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$${item.amount.toFixed(2)}`,
                amountNumber: item.amount,
                rate:
                  item.date < new Date("2024-05-02").getTime()
                    ? "-"
                    : `${item.apportionRate ? item.apportionRate : 0}%`,
                method:
                  item.date < new Date("2024-05-02").getTime()
                    ? "-"
                    : item.apportionMethod,
                claim:
                  item.date < new Date("2024-05-02").getTime()
                    ? "-"
                    : item.apportionRate && item.apportionMethod
                    ? `$${(
                        (item.amount * 0.15 * item.apportionRate) /
                        100
                      ).toFixed(2)}`
                    : null,
                depreciationRate:
                  item.amount < 1000 ? 0 : item.depreciationRate,
                depreciation:
                  item.depreciationRate &&
                  calculatedDepreciation.totalDepreciation === 0
                    ? "-"
                    : `$${calculatedDepreciation.totalDepreciation}`, //calculatedDepreciation .toFixed(2)
                // `${
                //   item.amount < 1000
                //     ? "WO"
                //     : item.depreciationMethod === "Straight Line"
                //     ? "SL"
                //     : "DV"
                // } ${item.amount < 1000 ? 100 : item.depreciationRate}% $${
                //   item.amount < 1000
                //     ? (
                //         ((item.amount - item.amount * 0.15) * 100) /
                //         100
                //       ).toFixed(2)
                //     : (
                //         ((item.amount - item.amount * 0.15) *
                //           item.depreciationRate) /
                //         100
                //       ).toFixed(2)
                // }`,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
                bookValue:
                  calculatedDepreciation.currentValue === 0
                    ? "-"
                    : item.depreciationRate &&
                      `$${calculatedDepreciation.currentValue.toFixed(2)}`,
                edit: (
                  <Button
                    type="text"
                    icon={
                      <EditOutlined
                        style={{ color: "dodgerblue" }}
                        onClick={() => {
                          setActiveItem(item);
                          setIsModal(true);
                        }}
                      />
                    }
                  />
                ),
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;
            let bookValue = 0;

            pageData
              .filter((item) => !item.isDeleted)
              .forEach((item) => {
                total += item.amountNumber;
              });

            pageData
              .filter((item) => !item.isDeleted)
              .forEach((item) => {
                let itemValue;
                if (typeof item.bookValue === "number") {
                  itemValue =
                    item.depreciationRate &&
                    calculateBookValue(
                      item.amountNumber,
                      new Date(item.date).getTime(),
                      item.depreciationRate
                    );
                }
                if (typeof itemValue === "number") bookValue += itemValue;
              });

            let totalDep = 0;
            let totalBookValue = 0;
            let totalPurchase = 0;
            let totalGSTAmount = 0;

            data
              .filter((item) => !item.isDeleted)
              .filter(
                (item) => item.date >= dateRange[0] && item.date < dateRange[1]
              )
              .filter((item) => item.category === "Assets")
              .sort((a, b) => (a.date < b.date ? -1 : 1))
              .forEach((item) => {
                const calculatedDepreciation =
                  calculateStraightLineDepreciation(
                    item.date,
                    item.amount,
                    item.depreciationRate ? item.depreciationRate : 0
                  );

                if (
                  item.gstNumber &&
                  item.gstNumber?.length > 5 &&
                  item.date > new Date("2024-05-02").getTime()
                ) {
                  totalGSTAmount += item.amount * 0.15;
                }

                totalDep += calculatedDepreciation.totalDepreciation;
                totalBookValue += calculatedDepreciation.currentValue;
                totalPurchase += item.amount;
              });

            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total GST: $(${totalGSTAmount.toFixed(
                2
              )}) | Total cost of asset purchases: $(${totalPurchase.toFixed(
                2
              )}) | Total depreciation: $(${totalDep.toFixed(
                2
              )}) | Total book value: $(${totalBookValue.toFixed(2)})`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Expenses`}
              </div>
              <Popover content="Money flowing out of the business for services, subscriptions and other expenses (Excluding salaries and wages)">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={[
            {
              title: "",
              dataIndex: "edit",
              key: "edit",
            },
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
            {
              title: "Category",
              dataIndex: "subCategory",
              key: "subCategory",
            },
            {
              title: "Vendor",
              dataIndex: "vendor",
              key: "vendor",
            },
            {
              title: "GST Number",
              dataIndex: "gstNumber",
              key: "gstNumber",
            },
            {
              title: "GST Method",
              dataIndex: "method",
              key: "method",
            },
            {
              title: "GST Rate",
              dataIndex: "rate",
              key: "rate",
            },
            {
              title: "GST Claim",
              dataIndex: "claim",
              key: "claim",
            },
            {
              title: "Cost Value",
              dataIndex: "amount",
              key: "amount",
            },
          ]}
          dataSource={data
            .filter((item) => !item.isDeleted)
            .filter(
              (item) => item.date >= dateRange[0] && item.date < dateRange[1]
            )
            .filter((item) => item.category === "Expenses")
            .filter((item) => item.subCategory !== "Salaries and Wages")
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$(${item.amount.toFixed(2)})`,
                amountNumber: item.amount,
                rate:
                  item.date < new Date("2024-05-02").getTime()
                    ? "-"
                    : `${item.apportionRate ? item.apportionRate : 0}%`,
                method:
                  item.date < new Date("2024-05-02").getTime()
                    ? "-"
                    : item.apportionMethod,
                claim:
                  item.date < new Date("2024-05-02").getTime()
                    ? "-"
                    : item.apportionRate && item.apportionMethod
                    ? `$${(
                        (item.amount * 0.15 * item.apportionRate) /
                        100
                      ).toFixed(2)}`
                    : null,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
                edit: (
                  <Button
                    type="text"
                    icon={
                      <EditOutlined
                        style={{ color: "dodgerblue" }}
                        onClick={() => {
                          setActiveItem(item);
                          setIsModal(true);
                        }}
                      />
                    }
                  />
                ),
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total cost of expenses: $(${total.toFixed(2)})`}</div>
            );
          }}
        />
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Salaries & Wages`}
              </div>
              <Popover content="Money flowing out of the business to pay for staff wages and shareholder salaries">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={columns}
          dataSource={data
            .filter((item) => !item.isDeleted)
            .filter(
              (item) => item.date >= dateRange[0] && item.date < dateRange[1]
            )
            .filter(
              (item) =>
                item.category === "Expenses" &&
                item.subCategory === "Salaries and Wages"
            )
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map((item) => {
              return {
                ...item,
                date: new Date(item.date).toLocaleDateString("en-GB"),
                amount: `$(${item.amount.toFixed(2)})`,
                amountNumber: item.amount,
                gst:
                  item.gstNumber && item.gstNumber?.length > 5
                    ? `$${(item.amount * 0.15).toFixed(2)}`
                    : null,
                key: item.id,
                edit: (
                  <Button
                    type="text"
                    icon={
                      <EditOutlined
                        style={{ color: "dodgerblue" }}
                        onClick={() => {
                          setActiveItem(item);
                          setIsModal(true);
                        }}
                      />
                    }
                  />
                ),
              };
            })}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
          footer={(pageData) => {
            let total = 0;

            pageData.forEach((item) => {
              total += item.amountNumber;
            });
            return (
              <div
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  textAlign: "right",
                  width: "100%",
                }}
              >{`Total cost of personnel: $(${total.toFixed(2)})`}</div>
            );
          }}
        />
        <Form form={balanceForm} layout="inline">
          <Form.Item
            label="Total Liabilities"
            name="liabilities"
            rules={[{ required: true }, { type: "number" }]}
          >
            <InputNumber placeholder="Liabilities" />
          </Form.Item>
          <Form.Item
            label="Total Cash Assets"
            name="cashAssets"
            rules={[{ required: true }, { type: "number" }]}
          >
            <InputNumber placeholder="Cash Assets" />
          </Form.Item>
          <Button onClick={() => processBalanceSheet()}>
            <ReloadOutlined />
          </Button>
        </Form>
        <Table
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  //padding: "10px",
                  textAlign: "left",
                  //width: "100%",
                  color: "dodgerblue",
                  fontSize: "1.5rem",
                  marginRight: "1rem",
                }}
              >
                {`${new Date(dateRange[0]).toLocaleDateString(
                  "en-GB"
                )} - ${new Date(dateRange[1]).toLocaleDateString(
                  "en-GB"
                )} Summary`}
              </div>
              <Popover content="Table of calculated summary data">
                <InfoCircleOutlined style={{ color: "rgb(200,200,200)" }} />
              </Popover>
            </div>
          )}
          columns={summaryColumns}
          dataSource={summaryData}
          size="small"
          style={{ width: "90%" }}
          pagination={false}
        />
      </div>
      <Modal
        open={isModal}
        onCancel={() => {
          setIsModal(false);
          setActiveItem(null);
        }}
        footer={null}
      >
        <Finances activeItem={activeItem} />
      </Modal>
    </div>
  );
};
