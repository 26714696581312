import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentIntentResult, Stripe, loadStripe } from "@stripe/stripe-js";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { db } from "../firebase";
import { Button, Form } from "antd";
import { useNavigate } from "react-router-dom";

const CheckoutForm = () => {
  return (
    <form>
      <button>Submit</button>
    </form>
  );
};

export const Payment = ({
  userId,
  paymentId,
  clientSecret,
}: {
  userId: string;
  paymentId: string | null;
  clientSecret: string;
}) => {
  const publishableKey =
    "pk_test_51JD4vzHvKRPRj7FTdhGjcFEokwJFHiLyzgvVqFSBUvWweeBzTh6Yd4JeHK5rPsvZAhRGeuQK5DBGAlJtBfz0DaoZ00WwVEY2lp";
  const stripePromise = loadStripe(publishableKey);
  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: clientSecret, appearance: { theme: "stripe" } }}
    >
      <InternalPay
        userId={userId}
        paymentId={paymentId}
        clientSecret={clientSecret}
      />
    </Elements>
  );
};

const InternalPay = ({
  userId,
  paymentId,
  clientSecret,
}: {
  userId: string;
  paymentId: string | null;
  clientSecret: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<null | string>(null);
  const nav = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // const clientSecret =

    // new URLSearchParams(window.location.search).get(
    //   "payment_intent_client_secret"
    // );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }: PaymentIntentResult) => {
        if (paymentIntent?.status) {
          updateDoc(
            doc(db, `stripe_customers/${userId}/payments/${paymentId}`),
            { status: paymentIntent?.status }
          );
        }

        switch (paymentIntent?.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe, clientSecret, paymentId, userId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          maxWidth: "600px",
          padding: "3rem",
          backgroundColor: "rgba(200,200,200, .9)",
          borderRadius: "1rem",
        }}
      >
        <Form>
          <PaymentElement />
          <Button
            onClick={async () => {
              if (!stripe) return;
              if (!elements) return;

              await stripe
                .confirmPayment({
                  elements,
                  confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url:
                      "https://c335-2404-4404-270b-5d00-532-3968-2e62-b29d.ngrok-free.app/thanks",
                  },
                })
                .then((res) => {
                  if (res.error.message) {
                    setMessage(res.error.message);
                  } else {
                    if (!stripe) return;
                    stripe
                      .retrievePaymentIntent(clientSecret)
                      .then(({ paymentIntent }: PaymentIntentResult) => {
                        if (paymentIntent?.status) {
                          updateDoc(
                            doc(
                              db,
                              `stripe_customers/${userId}/payments/${paymentId}`
                            ),
                            { status: paymentIntent?.status }
                          );
                        }
                      });

                    nav("/thanks");
                  }
                });
            }}
          >
            Pay now
          </Button>
        </Form>
      </div>
    </div>
  );
};
