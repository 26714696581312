import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
mapboxgl.accessToken =
  "pk.eyJ1IjoicGhpbGlwc2hhdyIsImEiOiJja3AyZHg5dXowMTlrMzBzNGw0aGFpaTltIn0.CYIlAmi0Yrj7t1IFy0ioxA";
interface Location {
  longitude: number;
  latitude: number;
  timeInSeconds: number;
  description: string | null;
}

interface MapProps {
  locations: Location[];
}

export const MapTool = ({ locations }: MapProps): JSX.Element => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/philipshaw/cm2s3xfue004801r7ek8y8lwg", //"mapbox://styles/mapbox/satellite-v9", // You can use any style you prefer
        center: [174.77614466019273, -41.28675449342123], // Starting position [lng, lat]
        zoom: 1.2, // Starting zoom level
        projection: "globe", // Display the map as a 3D globe
      });

      // Add navigation controls to the map
      //map.addControl(new mapboxgl.NavigationControl());
      //   mapRef.current.on("style.load", () => {
      //     mapRef.current!.setFog({}); // Set the default atmosphere style
      //   });

      const map = mapRef.current;

      if (map) {
        let timeoutId: NodeJS.Timeout;

        const animateToLocation = (index: number) => {
          if (index >= locations.length) return;

          const { longitude, latitude, timeInSeconds, description } =
            locations[index];

          map.flyTo({
            center: [longitude, latitude],
            essential: true,
            duration: timeInSeconds * 1000,
          });

          //
          const el = document.createElement("div");
          el.className = "custom-marker";
          el.innerText = description ? description : "";

          // Apply custom styles to the label
          el.style.backgroundColor = description ? "#ffffff" : "rgba(0,0,0,0)";
          el.style.color = "#9c1974";
          el.style.fontWeight = "bold";
          el.style.fontSize = "14px";
          el.style.padding = "8px";
          el.style.borderRadius = "5px";

          const marker = new mapboxgl.Marker(el)
            .setLngLat([longitude, latitude]) // Set the position of the marker
            .addTo(map);

          timeoutId = setTimeout(() => {
            marker.remove();
            animateToLocation(index + 1);
          }, timeInSeconds * 1000);
        };

        animateToLocation(0);

        return () => {
          clearTimeout(timeoutId);
          map.remove();
        };
      }
    }
  }, [locations]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "black",
        width: "100vw",
      }}
    >
      <div
        ref={mapContainerRef}
        style={{
          width: "100vw",
          height: "100vh",
          zIndex: 10,
          backgroundColor: "rgba(0,0,0,0)",
        }}
      />
    </div>
  );
};
