import { Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { HuePicker, SketchPicker } from "react-color";

var ColorScheme = require("color-scheme");

export const ColorPalette = () => {
  const [colors, setColors] = useState<Array<string> | null>(null);
  const [contrastColors, setContrastColors] = useState<Array<string> | null>(
    null
  );
  const [initialColor, setInitialColor] = useState<any>({
    hex: "#ffed00",
    hsl: { h: 55.82278481012658, s: 1, l: 0.5, a: 1 },
  });

  const [schemeSelector, setSchemeSelector] = useState<string>("mono");
  const [intensitySelector, setIntensitySelector] = useState<string>("default");
  const [paletteTitle, setPaletteTitle] = useState<string>("Untitled");
  const [isVis, setIsVis] = useState(false);

  useEffect(() => {
    var scheme = new ColorScheme();
    scheme
      .from_hue(initialColor.hsl.h) // Start the scheme
      //.distance(.75) does not work for mono
      .scheme(schemeSelector) // Use the 'triade' scheme, that is, colors // mono, contrast, triade, tetrade, analogic,
      //complement([bool]) works with analogic
      // selected from 3 points equidistant around
      // the color wheel.
      .variation(intensitySelector); // Use the 'soft' color variation // default, pastel, soft, light, hard, pale

    setColors(() => [...scheme.colors()]);

    var schemeTwo = new ColorScheme();
    schemeTwo
      .from_hue(initialColor.hsl.h) // Start the scheme
      //.distance(.75) does not work for mono
      .scheme("contrast") // Use the 'triade' scheme, that is, colors // mono, contrast, triade, tetrade, analogic,
      //complement([bool]) works with analogic
      // selected from 3 points equidistant around
      // the color wheel.
      .variation(intensitySelector); // Use the 'soft' color variation // default, pastel, soft, light, hard, pale

    setContrastColors(() => [...schemeTwo.colors()]);
  }, [initialColor, schemeSelector, intensitySelector, paletteTitle]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ width: "50%", height: "100%" }}>
        {colors &&
          colors.map((color, idx) => {
            return (
              <div
                key={idx}
                style={{
                  backgroundColor: `#${color}`,
                  width: "100%",
                  height: `${100 / colors.length}%`,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() => setIsVis(true)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  {idx === 0 && (
                    <div
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: ".25rem",
                        borderRadius: "5px",
                        alignSelf: "center",
                        fontSize: "9pt",
                      }}
                    >
                      {`${paletteTitle} ${schemeSelector} ${intensitySelector} #${color}`}
                    </div>
                  )}
                  {idx !== 0 && (
                    <div
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: ".25rem",
                        borderRadius: "5px",
                        alignSelf: "center",
                        fontSize: "9pt",
                      }}
                    >
                      {`#${color}`}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ width: "50%", height: "100%" }}>
        {contrastColors &&
          contrastColors.map((color, idx) => {
            return (
              <div
                key={idx}
                style={{
                  backgroundColor: `#${color}`,
                  width: "100%",
                  height: `${100 / contrastColors.length}%`,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() => setIsVis(true)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  {idx !== 0 && (
                    <div
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: ".25rem",
                        borderRadius: "5px",
                        alignSelf: "center",
                        fontSize: "9pt",
                      }}
                    >
                      {`#${color}`}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <Modal
        visible={isVis}
        footer={null}
        title="Colour Selector"
        onCancel={() => setIsVis(false)}
        centered
        mask={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form layout="vertical">
            <Form.Item>
              <HuePicker
                color={initialColor.hex}
                onChange={(e) => setInitialColor(e)}
              />
            </Form.Item>
            <Form.Item>
              <Select
                placeholder="Scheme"
                onChange={(value) => setSchemeSelector(() => value)}
              >
                <Select.Option value={"mono"}>Mono</Select.Option>
                <Select.Option value={"contrast"}>Contrast</Select.Option>
                <Select.Option value={"triade"}>Triade</Select.Option>
                <Select.Option value={"tetrade"}>Tetrade</Select.Option>
                <Select.Option value={"analogic"}>Analogic</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Select
                placeholder="Variation"
                onChange={(value) => setIntensitySelector(() => value)}
              >
                <Select.Option value={"default"}>Default</Select.Option>
                <Select.Option value={"pastel"}>Pastel</Select.Option>
                <Select.Option value={"soft"}>Soft</Select.Option>
                <Select.Option value={"light"}>Light</Select.Option>
                <Select.Option value={"hard"}>Hard</Select.Option>
                <Select.Option value={"pale"}>Pale</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Input
                placeholder="Palette title"
                onChange={(e) => setPaletteTitle(e.target.value)}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
