import { Button, DatePicker, Form, Input, message, Select } from "antd";
import { doc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { db } from "../firebase";
import { EventType } from "../typeDefs";

export const CreateEvent = () => {
  const [eventForm] = Form.useForm();

  const handleForm = () => {
    eventForm
      .validateFields()
      .then((values) => {
        const newId = nanoid();
        const dateOfEvent = values.date.toDate().getTime();
        setDoc(doc(db, `events/${newId}`), {
          ...values,
          isDeleted: false,
          dateCreated: new Date().getTime(),
          date: dateOfEvent,
        } as EventType)
          .then(() => {
            message.success("Event created");
          })
          .catch((err) => console.error(err));
      })
      .then(() => eventForm.resetFields())
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <h1>Create Event</h1>
      <Form form={eventForm} initialValues={{ type: "Meeting" }}>
        <Form.Item name="type">
          <Select>
            <Select.Option value="Meeting">Meeting</Select.Option>
            <Select.Option value="Event">Event</Select.Option>
            <Select.Option value="Task">Task</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="title">
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item name="description">
          <Input.TextArea rows={6} placeholder="Description" />
        </Form.Item>
        <Form.Item name="date">
          <DatePicker showTime />
        </Form.Item>
      </Form>
      <Button onClick={() => handleForm()}>Save</Button>
    </div>
  );
};
