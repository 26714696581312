import { Card } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

type ProjectType = {
  title: string;
  titleImage: string;
  dateAdded: number;
  subTitle: string;
  content: Array<{ element: string; content: string }>;
  gallery?: Array<{ caption: string; image: string }>;
};

export const Projects = () => {
  const projectList: Array<ProjectType> = [
    // {
    //   title: "Gott Mitt Uns",
    //   titleImage:
    //     "https://gpsdata-5f5b9.web.app/static/media/soldier.04f33a39.JPG",
    //   dateAdded: 15378534737,
    //   subTitle: "Can God be present with those on the wrong side of history?",
    //   content: [
    //     {
    //       element: "paragraph",
    //       content:
    //         "Gott Mit Uns is a short film about a German Wehrmacht Sargent in WWII that gets his ideas about the purpose of the war, and of God, challenged by a more junior soldier.",
    //     },
    //     {
    //       content:
    //         "The film was shot for the Tropfest short film festival. The festival requires a single signature item to be included in the film. In this film it was a candle so we gave ourselves the challenge to see if we could light the set solely with candles. This challenge was too much to bare and we had a lot of noise in the footage. We ended up using some studio lights in the second half of the film.",
    //       element: "paragraph",
    //     },
    //     {
    //       element: "paragraph",
    //       content:
    //         "This project gave us some very useful experience in production design, including costumes and set dressing. It taught us about the importance of lighting for film and some technical considerations when it come to designing a set for post-production special effects.",
    //     },
    //   ],
    //   //gallery:
    //   // [
    //   //   {
    //   //     caption: "Special Effects Make-up",
    //   //     image:
    //   //       "https://gpsdata-5f5b9.web.app/static/media/soldier.04f33a39.JPG",
    //   //   },
    //   //   {
    //   //     caption: "Costume Design ",
    //   //     image:
    //   //       "https://gpsdata-5f5b9.web.app/static/media/soldier.04f33a39.JPG",
    //   //   },
    //   //   {
    //   //     caption: "Special Effects Make-up",
    //   //     image:
    //   //       "https://gpsdata-5f5b9.web.app/static/media/soldier.04f33a39.JPG",
    //   //   },
    //   //   {
    //   //     caption: "Special Effects Make-up",
    //   //     image:
    //   //       "https://gpsdata-5f5b9.web.app/static/media/soldier.04f33a39.JPG",
    //   //   },
    //   // ],
    // },
    {
      title: "I Wish",
      titleImage:
        "https://scryptworxstudios.web.app/static/media/bg.9171d674268ab8c6653b.png",
      dateAdded: 1676789233590,
      subTitle:
        "War takes whatever it should chose, and man giveth that which he cannot afford to lose.",
      content: [
        {
          element: "paragraph",
          content:
            "I Wish, is a film produced for the 48Hour film competition in 2022. We were given the genre 'Coming of age'. We also had to include four elements in the film. These were: 1) A birds-eye-view or worms eye view shot, 2) a gate keeper, 3) a whisper, and 4) a sign.",
        },
        {
          element: "paragraph",
          content:
            "The story is about a school boy who has lost his father to war and must navigate the events of his life without his father present.",
        },
        {
          element: "paragraph",
          content:
            "In this project, our main focus was on the set design and cinematography of the war scenes. This project gave us the experience of working in a remote location at nighttime with lighting and basic on set special effects. We think we pulled this scene off very well with a realistic looking sequence.",
        },
      ],
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,.8)",
      }}
    >
      <div
        style={{
          fontFamily: "oxanium",
          fontSize: "1.5rem",
          marginBottom: "1.5rem",
          alignSelf: "center",
          color: "white",
        }}
      >
        Our Projects
      </div>
      <div
        style={{
          width: "90%",
          maxWidth: "1000px",
          alignSelf: "center",
        
        }}
      >
        {projectList.map((project) => {
          return (
            <Card
              cover={<img src={project.titleImage} alt="Lights" width="100%" />}
              style={{ paddingBottom: "5rem" }}
            >
              <div
                style={{
                  fontFamily: "oxanium",
                  fontSize: "1.5rem",
                  marginBottom: "1.5rem",
                  alignSelf: "center",
                }}
              >
                {project.title}
              </div>
              <div
                style={{
                  fontFamily: "oxanium",
                  fontSize: ".8rem",
                  marginBottom: "1.5rem",
                }}
              >
                {project.subTitle}
              </div>

              {project.content.map((item) => {
                if (item.element === "paragraph") {
                  return (
                    <div
                      style={{
                        alignSelf: "center",
                        width: "100%",
                        textAlign: "justify",
                        marginBottom: "1rem",
                      }}
                    >
                      {item.content}
                    </div>
                  );
                }
                if (item.element === "image") {
                  return (
                    <div className="p-6 px-40 flex-col self-center text-center">
                      <img src={item.content} alt="Lights" width="50%" />
                    </div>
                  );
                }
                if (project.gallery) {
                  return (
                    <div
                      className="self-center text-center"
                      style={{ width: "100%" }}
                    >
                      <div
                        className=" text-black font-semibold mb-6 mt-10 font-bold, text-xl"
                        style={{ fontFamily: "oxanium" }}
                      >
                        Project Gallery
                      </div>
                      <Carousel className="self-center content-center">
                        {project.gallery.map((entry, idx) => {
                          return (
                            <div key={idx}>
                              {entry.image && (
                                <img
                                  src={entry.image}
                                  alt={`Services: ${idx}`}
                                />
                              )}
                              <img src={entry.image} alt={`Services: ${idx}`} />
                              <p className="legend">{entry.caption}</p>
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>
                  );
                }
                return null;
              })}
            </Card>
          );
        })}
      </div>
    </div>
  );
};
