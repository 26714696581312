import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { scriptState } from "../recoil/recoil";
import { useRecoilValue } from "recoil";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Scene } from "../typeDefs";
import moment from "moment";
import { ScenePDF } from "./ScenePDF";
const { RangePicker } = DatePicker;

//location - gps, fees, permissions, admin area, access, power source, conditions, facilaties, DateIN, DateOUT, timeIN, timeOUT, contactPer, contactNum
// location - catering - [meal, facilities, rubbish, water]
//Art- Set - Dressing - [description]
//Art- Set - Props - [description]
//Art- Costumes - [Description]
//VFX - Bluescreen, greenscreen, explosions, day4night, LED, atmosphere, minitures, CGI
//VFX - Firearms [type, blank firing, plan complete]
//Actors - [Person Profile]
//Actors - [Extras]
//Cameras - [Type, Lense, Power source, supports, memory, monitor, other]
//Sound - Microphones  - [type, power source, supports, cable, other]
//Sound - Recorders - [type, power source, monitor, cable, other, memory]
//SFX - [music, foley, ADR, Narration]
//Lights - Naturals - [type, description, gel, cable, modifier ]
//Lights - Other - [type, description, gel, cable, modifier ]
//Movement - [type, transport sys, weights, supports, powersource]
//Other - [description]
//Expertise - [description]
// link to scene - [...sceneIDs]

export const ScenePlan = () => {
  const [locForm] = Form.useForm();
  const [setForm] = Form.useForm();
  const [artForm] = Form.useForm();
  const [vfxForm] = Form.useForm();
  const [talForm] = Form.useForm();
  const [camForm] = Form.useForm();
  const [movForm] = Form.useForm();
  const [ligForm] = Form.useForm();
  const [souForm] = Form.useForm();
  const [sfxForm] = Form.useForm();
  const [firForm] = Form.useForm();
  const [traForm] = Form.useForm();
  const [catForm] = Form.useForm();
  const params = useParams();
  const [scene, setScene] = useState<Scene | null>(null);
  const [isFirearms, setIsFirearms] = useState(false);
  const [isBCam, setIsBCam] = useState(false);
  const [isDrone, setIsDrone] = useState(false);
  const [isUW, setISUW] = useState(false);

  const script = useRecoilValue(scriptState);
  const sceneRef = doc(db, `scripts/${script?.id}/scenes/${params.sceneid}`); //
  useEffect(() => {
    const unsub = onSnapshot(sceneRef, (snapdoc) => {
      setScene(() => {
        return { ...snapdoc.data(), id: snapdoc.id } as Scene;
      });
    });

    console.log({ scene });

    return unsub;
  }, [params, script]);

  const handleLocForm = () => {
    locForm
      .validateFields()
      .then((values) => locFunc(values))
      .catch((err) => console.error(err));
  };

  const locFunc = (values: any) => {
    updateDoc(doc(db, `scripts/${script?.id}/scenes/${params.sceneid}`), {
      ...values,
      dates: [
        values.dates[0].toDate().getTime(),
        values.dates[1].toDate().getTime(),
      ],
    })
      .then(() => message.success("Updated location info"))
      .catch((err) => console.error(err));
  };

  const otherFunc = (values: any) => {
    updateDoc(doc(db, `scripts/${script?.id}/scenes/${params.sceneid}`), values)
      .then(() => message.success("Updated info"))
      .catch((err) => console.error(err));
  };

  const handleForm = (form: FormInstance<any>) => {
    form
      .validateFields()
      .then((values) => otherFunc(values))
      .catch((err) => console.error(err));
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {scene && (
        <>
          <div>{scene.id}</div>
          {/* Location Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              LOCATION PLAN
            </div>
            <Form
              form={locForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="locDescription">
                <Input.TextArea placeholder="What is the name/description of the location" />
              </Form.Item>
              <Form.Item name="contactPer">
                <Input placeholder="Who is the location contact person" />
              </Form.Item>
              <Form.Item name="contactNum">
                <Input placeholder="Phone and/or email" />
              </Form.Item>
              <Form.Item name="dates">
                <RangePicker showTime />
              </Form.Item>
              <Form.Item name="fees">
                <InputNumber placeholder="Fees" />
              </Form.Item>
              <Form.Item name="gpsLat">
                <Input placeholder="Lat" />
              </Form.Item>
              <Form.Item name="gpsLong">
                <Input placeholder="Long" />
              </Form.Item>
              <Form.Item name="facilities">
                <Input.TextArea placeholder="What facilities are available for use?" />
              </Form.Item>
              <Form.Item name="access">
                <Input.TextArea placeholder="What is the access like to the venue?" />
              </Form.Item>
              <Form.Item name="adminArea">
                <Input.TextArea placeholder="Where will the admin area be set up?" />
              </Form.Item>
              <Form.Item name="conditions">
                <Input.TextArea placeholder="Are there any location specific conditions or considerations?" />
              </Form.Item>
              <Form.Item name="power">
                <Select allowClear showSearch style={{ textAlign: "left" }}>
                  <Select.Option value="mains">
                    Mains power available
                  </Select.Option>
                  <Select.Option value="gene">Generator required</Select.Option>
                  <Select.Option value="batts">Batteries only</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <Button onClick={() => handleLocForm()}>Save</Button>
          </div>
          {/* Location Plan ENDS - - Set Design Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              SET DESIGN PLAN
            </div>
            <Form
              form={setForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="setPeriod">
                <Input.TextArea placeholder="What is the period and style of the scene?" />
              </Form.Item>
              <Form.Item name="colourPalette">
                <Input.TextArea placeholder="What colour palette are we using?" />
              </Form.Item>
              <Form.Item name="setTexAndDetail">
                <Input.TextArea placeholder="How are we going to texture and detail the set?" />
              </Form.Item>
              <Form.Item name="setContinuity">
                <Input.TextArea placeholder="Is this set maintaining continuity with the other scenes?" />
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(setForm)}>Save</Button>
          </div>
          {/* Location Plan ENDS - - Set Design Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              ART DESIGN PLAN
            </div>
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              PROPS
            </div>
            <Form
              form={artForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="propsSet">
                <Input.TextArea placeholder="What props are we using in this set?" />
              </Form.Item>
              <Form.Item name="propsActors">
                <Input.TextArea placeholder="What props will be carried or used by actors?" />
              </Form.Item>
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                COSTUMES
              </div>
              <Form.Item name="costumesMain">
                <Input.TextArea placeholder="What costumes will the actors wear?" />
              </Form.Item>
              <Form.Item name="costumeExtras">
                <Input.TextArea placeholder="What costumes will the extras wear?" />
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(artForm)}>Save</Button>
          </div>
          {/* Set Design Plan ENDS - - VFX Plan BEGINS - Bluescreen, greenscreen, explosions, day4night, LED, atmosphere, minitures, CGI */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              VFX PLAN
            </div>
            <Form
              form={vfxForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="chromaKeyScreens">
                <Select
                  style={{ textAlign: "left" }}
                  placeholder="Chroma key screen"
                >
                  <Select.Option value="none">Not required</Select.Option>
                  <Select.Option value="blue">Blue screen</Select.Option>
                  <Select.Option value="green">Green screen</Select.Option>
                  <Select.Option value="white">White screen</Select.Option>
                  <Select.Option value="black">Black screen</Select.Option>
                  <Select.Option value="image">Image screen</Select.Option>
                  <Select.Option value="led">LED screen</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="pyro" valuePropName="checked">
                <Checkbox value={[true, false]}>
                  Are we going to use explosions and/or smoke?
                </Checkbox>
              </Form.Item>
              <Form.Item name="atmosphere" valuePropName="checked">
                <Checkbox value={[true, false]}>
                  Are we using a smoke machine for atmospheric density?
                </Checkbox>
              </Form.Item>
              <Form.Item name="day4night" valuePropName="checked">
                <Checkbox value={[true, false]}>
                  Are we shooting day-for-night?
                </Checkbox>
              </Form.Item>
              <Form.Item name="minitures">
                <Input.TextArea placeholder="Description of miniture models required" />
              </Form.Item>
              <Form.Item name="cgi">
                <Input.TextArea placeholder="Description of CGI elements" />
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(vfxForm)}>Save</Button>
          </div>
          {/* VFX ENDS - - Talent Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              TALENT PLAN
            </div>
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              ACTORS
            </div>
            <Form
              form={talForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="actors">
                <Input.TextArea placeholder="Who are the actors that appear in this scene?" />
              </Form.Item>
              <Form.Item name="numActors">
                <InputNumber placeholder="Number" title="Number of Actors" />
              </Form.Item>
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                EXTRAS
              </div>
              <Form.Item name="extrasRequired" valuePropName="checked">
                <Checkbox value={[true, false]}>
                  Are we using extras in this scene?
                </Checkbox>
              </Form.Item>
              <Form.Item name="extras">
                <Input.TextArea placeholder="How are we using the extras in this scene?" />
              </Form.Item>
              <Form.Item name="numExtras">
                <InputNumber placeholder="Number" title="Number of Extras" />
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(talForm)}>Save</Button>
          </div>
          {/* Talent Plan ENDS - - Camera Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              CAMERA PLAN
            </div>
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              A CAMERA
            </div>
            <Form
              form={camForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="aCamera">
                <Select style={{ textAlign: "left" }} placeholder="A Camera">
                  <Select.Option value="BMPCC6K">BMPCC6K</Select.Option>
                  <Select.Option value="Sony A6300">Sony A6300</Select.Option>
                  <Select.Option value="Hire Camera">
                    Hire/loan camera
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="aCameraPower">
                <Select
                  style={{ textAlign: "left" }}
                  placeholder="A Camera power supply"
                >
                  <Select.Option value="Mains power">Mains power</Select.Option>
                  <Select.Option value="Sony NPF Batteries">NPF</Select.Option>
                  <Select.Option value="Powerbank">Powerbanks</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="aCameraSupport">
                <Select
                  style={{ textAlign: "left" }}
                  placeholder="A Camera support system"
                >
                  <Select.Option value="Tripod">Tripod</Select.Option>
                  <Select.Option value="Shoulder Rig">
                    Shoulder rig
                  </Select.Option>
                  <Select.Option value="Gimbal">Gimbal</Select.Option>
                </Select>
              </Form.Item>
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                B CAMERA
              </div>
              <Form.Item name="bCameraRequired" valuePropName="checked">
                <Checkbox
                  value={[true, false]}
                  onChange={(val) => setIsBCam(val.target.checked)}
                >
                  Is the B Camera required?
                </Checkbox>
              </Form.Item>
              {isBCam && (
                <>
                  <Form.Item name="bCamera">
                    <Select
                      style={{ textAlign: "left" }}
                      placeholder="B Camera"
                    >
                      <Select.Option value="BMPCC6K">BMPCC6K</Select.Option>
                      <Select.Option value="Sony A6300">
                        Sony A6300
                      </Select.Option>
                      <Select.Option value="Hire Camera">
                        Hire/loan camera
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="bCameraPower">
                    <Select
                      style={{ textAlign: "left" }}
                      placeholder="B Camera power supply"
                    >
                      <Select.Option value="Mains power">
                        Mains power
                      </Select.Option>
                      <Select.Option value="Sony NPF Batteries">
                        NPF
                      </Select.Option>
                      <Select.Option value="Powerbank">
                        Powerbanks
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="bCameraSupport">
                    <Select
                      style={{ textAlign: "left" }}
                      placeholder="B Camera support system"
                    >
                      <Select.Option value="Tripod">Tripod</Select.Option>
                      <Select.Option value="Shoulder Rig">
                        Shoulder rig
                      </Select.Option>
                      <Select.Option value="Gimbal">Gimbal</Select.Option>
                    </Select>
                  </Form.Item>
                </>
              )}
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                AERIAL CAMERA
              </div>
              <Form.Item name="droneRequired" valuePropName="checked">
                <Checkbox
                  value={[true, false]}
                  onChange={(val) => setIsDrone(val.target.checked)}
                >
                  Are we going to need aerial video in this scene?
                </Checkbox>
              </Form.Item>
              {isDrone && (
                <>
                  <Form.Item name="part102Required" valuePropName="checked">
                    <Checkbox value={[true, false]}>
                      Is a Part 102 certification required?
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="drone">
                    <Select
                      style={{ textAlign: "left" }}
                      placeholder="Aerial System"
                    >
                      <Select.Option value="Mini Pro 3">
                        Mini Pro 3
                      </Select.Option>
                      <Select.Option value="Hire drone">
                        Hire drone
                      </Select.Option>
                      <Select.Option value="Helicopter">
                        Helicopter
                      </Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </>
              )}
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                UNDERWATER CAMERA
              </div>
              <Form.Item name="underwaterRequired" valuePropName="checked">
                <Checkbox
                  value={[true, false]}
                  onChange={(val) => setISUW(val.target.checked)}
                >
                  Are we filming underwater in this scene?
                </Checkbox>
              </Form.Item>
              {isUW && (
                <>
                  <Form.Item name="uwPlan">
                    <Input.TextArea placeholder="How are we going to achieve this?" />
                  </Form.Item>
                  <Form.Item name="uwCamera">
                    <Select
                      style={{ textAlign: "left" }}
                      placeholder="Under Water System"
                    >
                      <Select.Option value="Go Pro">Go Pro</Select.Option>
                      <Select.Option value="Custom">
                        Custom Housing
                      </Select.Option>
                      <Select.Option value="Hire">Hire</Select.Option>
                      <Select.Option value="Other">Other</Select.Option>
                    </Select>
                  </Form.Item>
                </>
              )}
            </Form>
            <Button onClick={() => handleForm(camForm)}>Save</Button>
          </div>
          {/* Set Design Plan ENDS - - Sound Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              CAMERA MOVEMENT PLAN
            </div>
            <Form
              form={movForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="movePlan">
                <Input.TextArea placeholder="How are we going to move the camera in this scene?" />
              </Form.Item>
              <Form.Item name="dollyRequired" valuePropName="checked">
                <Checkbox value={[true, false]}>Dolly</Checkbox>
              </Form.Item>
              <Form.Item name="craneRequired" valuePropName="checked">
                <Checkbox value={[true, false]}>Crane</Checkbox>
              </Form.Item>
              <Form.Item name="gimbalRequired" valuePropName="checked">
                <Checkbox value={[true, false]}>Gimbal</Checkbox>
              </Form.Item>
              <Form.Item name="steadycamRequired" valuePropName="checked">
                <Checkbox value={[true, false]}>Steadycam</Checkbox>
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(movForm)}>Save</Button>
          </div>
          {/* Set Design Plan ENDS - - Sound Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              LIGHTING PLAN
            </div>
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              MAIN
            </div>
            <Form
              form={ligForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="mainLights">
                <Input.TextArea placeholder="How are we going to light this scene?" />
              </Form.Item>
              <Form.Item name="mainLightsTemp">
                <Input placeholder="What is the colour temperature of the scene lights (Kelvins)" />
              </Form.Item>
              <Form.Item name="mainLightsModifier">
                <Input.TextArea placeholder="What specific light modifiers do we need?" />
              </Form.Item>
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  paddingBottom: "1rem",
                }}
              >
                PRACTICALS
              </div>
              <Form.Item name="practicals">
                <Input.TextArea placeholder="What practicals will give the scene a natural looking light source?" />
              </Form.Item>
              <Form.Item name="pracLightsTemp">
                <Input.TextArea placeholder="Do we need to adjust the strength or colour of the light? How will we achieve that?" />
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(ligForm)}>Save</Button>
          </div>
          {/* Set Design Plan ENDS - - Sound Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              SOUND PLAN
            </div>
            <Form
              form={souForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="soundCapture">
                <Input.TextArea placeholder="How are we going to capture scene audio?" />
              </Form.Item>
              <Form.Item name="soundInterference">
                <Input.TextArea placeholder="Is anything going to interfere with our capture?" />
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(souForm)}>Save</Button>
          </div>
          {/* Set Design Plan ENDS - - Sound Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              SOUND EFFECTS PLAN
            </div>
            <Form
              form={sfxForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              <Form.Item name="music">
                <Input.TextArea placeholder="What is the mood of this scene? What music will we use to set the mood?" />
              </Form.Item>
              <Form.Item name="foleyOnScene">
                <Input.TextArea placeholder="What Foley elements do we need to capture on scene?" />
              </Form.Item>
              <Form.Item name="foleyAfter">
                <Input.TextArea placeholder="What Foley elements can we capture after?" />
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(sfxForm)}>Save</Button>
          </div>
          {/* Set Design Plan ENDS - - Sound Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              FIREARMS PLAN
            </div>
            <Form
              form={firForm}
              initialValues={{
                ...scene,
                dates: scene.dates
                  ? [moment(scene.dates[0]), moment(scene.dates[1])]
                  : [null, null],
              }}
            >
              {" "}
              <Form.Item name="firearmsRequired" valuePropName="checked">
                <Checkbox
                  value={[true, false]}
                  onChange={(val) => setIsFirearms(val.target.checked)}
                >
                  Are there firearms in this scene?
                </Checkbox>
              </Form.Item>
              {isFirearms && (
                <>
                  <Form.Item name="blanksRequired" valuePropName="checked">
                    <Checkbox value={[true, false]}>
                      Are we using blanks in this scene?
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="firearmsPlan">
                    <Input.TextArea placeholder="Description of the firearms plan" />
                  </Form.Item>
                  <Form.Item
                    name="firearmsDocsComplete"
                    valuePropName="checked"
                  >
                    <Checkbox value={[true, false]}>
                      Is the firearms documentation complete?
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="firearmsPermissionComplete"
                    valuePropName="checked"
                  >
                    <Checkbox value={[true, false]}>
                      Have we got Police permission to use firearms for this
                      scene?
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="firearmsContactPer">
                    <Input placeholder="Contact person" />
                  </Form.Item>
                  <Form.Item name="firearmsContactNum">
                    <Input placeholder="Phone and/or email" />
                  </Form.Item>
                </>
              )}
            </Form>
            <Button onClick={() => handleForm(firForm)}>Save</Button>
          </div>
          {/* Set Design Plan ENDS - - Sound Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              TRANSPORT PLAN
            </div>
            <Form form={traForm} initialValues={scene}>
              <Form.Item name="transport">
                <Input.TextArea placeholder="How are we going to transport everyone to and from the set?" />
              </Form.Item>
              <Form.Item name="transportHire">
                <Input.TextArea placeholder="What is the transport hire or reimbursement plan?" />
              </Form.Item>
              <Form.Item name="transCost">
                <InputNumber placeholder="Cost" title="Transport costs" />
              </Form.Item>
            </Form>
            <Button onClick={() => handleForm(traForm)}>Save</Button>
          </div>
          {/* Set Design Plan ENDS - - Sound Plan BEGINS */}
          <div style={{ width: "500px", paddingBottom: "3rem" }}>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                paddingBottom: "1rem",
              }}
            >
              CATERING PLAN
            </div>
            <Form form={catForm} initialValues={scene}>
              <Form.Item name="food">
                <Input.TextArea placeholder="How are we going to feed everyone?" />
              </Form.Item>
              <Form.Item name="foodCost">
                <InputNumber placeholder="Cost" />
              </Form.Item>
              <Form.Item name="drink">
                <Input.TextArea placeholder="What are we going to provide for everyone to drink?" />
              </Form.Item>
              <Form.Item name="drinkCost">
                <InputNumber placeholder="Cost" />
              </Form.Item>
            </Form>

            <Button onClick={() => handleForm(catForm)}>Save</Button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "3rem",
            }}
          >
            <ScenePDF scene={scene} />
          </div>
        </>
      )}
    </div>
  );
};
