import {
  Button,
  Form,
  Image,
  Input,
  message,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { nanoid } from "nanoid";
import { useState } from "react";
import { db, storage } from "../firebase";
import uploadImage from "./upload.png";
import { LoadingOutlined } from "@ant-design/icons";

export const FileUpload = () => {
  const [newFile, setNewFile] = useState<RcFile | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>(
    "File name or title..."
  );
  const [imageForm] = Form.useForm();

  const handleSaveNew = (url: string) => {
    const newId = nanoid();

    imageForm
      .validateFields()
      .then((values) => {
        setDoc(doc(db, `uploads/${newId}`), {
          ...values,
          url: url,
          type: newFile?.type,
          isDeleted: false,
          dateCreated: new Date().getTime(),
          fileName: newFile?.name,
        }).then(() => {
          setUploading(false);
          setFileName(null);
          setNewFile(null);
          setPlaceholder("Add another file...");
          message.success("File uploaded");
          imageForm.resetFields();
        });
      })
      .catch((err) => {
        setUploading(false);
        setFileName(null);
        setNewFile(null);
        setPlaceholder("File name or title...");
        message.success("Failed to upload");

        console.error(err);
      });
  };

  const props: UploadProps = {
    beforeUpload: (file: RcFile) => {
      console.log(file.type);
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "audio/mpeg" ||
        file.type === "audio/wav" ||
        file.type === "audio/ogg" ||
        file.type === "application/pdf" ||
        file.type === "audio/mp4" ||
        file.type === "audio/webm" ||
        file.type === "audio/3gp" ||
        file.type === "audio/3gpp" ||
        file.type === "audio/m4a" ||
        file.type === "audio/aac" ||
        file.type === "audio/x-m4a" ||
        file.type === "video/mp4" ||
        file.type === "video/ogg" ||
        file.type === "video/webm";

      if (!isImage) {
        message.error("Sorry, file type not supported");
      } else {
        //setImageName(file.name);
        setNewFile(file);
      }

      return isImage;
    },
    accept:
      "image/png, image/jpg, image/jpeg, application/pdf, audio/ogg, audio/wav, audio/mpeg, audio/mp4, audio/webm, audio/x-m4a, audio/3gp, audio/3gpp ,audio/m4a, audio/aac, video/mp4, video/ogg, video/webm",
    customRequest: () => {
      setUploading(true);
      if (newFile) {
        //console.log(newFile);
        const storeRef = ref(storage, `uploads/${newFile.name}`);
        uploadBytes(storeRef, newFile)
          .then((res) => {
            handleSaveNew(res.metadata.fullPath);
          })
          .catch((err) => {
            setUploading(false);
            setFileName(null);
            setNewFile(null);
            setPlaceholder("File name or title...");
            message.error("Failed to upload");
            console.error(err);
          });
      }
    },
    maxCount: 1,
    showUploadList: false,
  };

  //   match /{document=**} {
  //     allow read, write: if request.auth.uid != null;
  // }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "15",
        backgroundColor: "rgba(0,0,0,0.9)",
      }}
    >
      <div style={{ width: "70%", maxWidth: "300px" }}>
        {uploading ? (
          <div>
            <Spin indicator={<LoadingOutlined spin />} size="large" />
            <div style={{ color: "white" }}>Uploading...</div>
          </div>
        ) : (
          <Form
            form={imageForm}
            onChange={() => setFileName(imageForm.getFieldValue("name"))}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input a file name!",
                },
              ]}
            >
              <Input placeholder={placeholder} />
            </Form.Item>
            {fileName && (
              <Upload {...props}>
                <Button type="text">
                  <Image
                    preview={false}
                    src={uploadImage}
                    style={{ width: "100%" }}
                  />
                </Button>
              </Upload>
            )}
          </Form>
        )}
      </div>
    </div>
  );
};
