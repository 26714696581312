// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGnsGsUzc7BtRu2xyqZtiSDP7qSMqzYDo",
  authDomain: "scryptworxstudios.firebaseapp.com",
  projectId: "scryptworxstudios",
  storageBucket: "scryptworxstudios.appspot.com",
  messagingSenderId: "561862887215",
  appId: "1:561862887215:web:b08df72f586641e8af83da",
  measurementId: "G-GNDYWHDYZQ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
