import { OrderedListOutlined, SettingOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Slider,
} from "antd";
import * as Scroll from "react-scroll";
import {
  ContentBlock,
  convertFromRaw,
  convertToRaw,
  DraftEntityType,
  DraftHandleValue,
  Editor,
  EditorState,
  RawDraftContentState,
  RichUtils,
} from "draft-js";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import { db } from "../firebase";
import { projectsState } from "../recoil/recoil";

export const Teleprompter = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const { YearPicker } = DatePicker;
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollInt = useRef<any>(null);
  const [isCitations, setIsCitations] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [currentScript, setCurrentScript] = useState<null | string>(null);
  const [sourceType, setSourceType] = useState("journal");
  const [citeId, setCiteId] = useState<null | string>(null);
  const [teleDocs, setTeleDocs] = useState<
    Array<{
      editor: RawDraftContentState;
      title: string;
      date: number;
      id: string;
    }>
  >([]);
  const [currentTitle, setCurrentTitle] = useState(new Date().toISOString());
  const [isModal, setIsModal] = useState(false);
  const [textAlignState, setTextAlignState] = useState("left");
  const [scrollSpeed, setScrollSpeed] = useState(95);
  const [containerWidth, setContainerWidth] = useState(40);
  const [isScrolling, setIsScrolling] = useState(false);

  const myblockStyleFn = (contentBlock: ContentBlock) => {
    const blockType = contentBlock.getType();
    if (isEditMode && blockType === "unstyled") {
      return "teleprompterEditStyle";
    }
    if (!isEditMode && blockType === "unstyled") {
      return "teleprompterReadStyle";
    }
    return "citation-block";
  };

  const loadEditor = (raw: Draft.RawDraftContentState) => {
    const newContentState = convertFromRaw(raw);
    setEditorState(() => EditorState.createWithContent(newContentState));
  };

  useEffect(() => {
    onSnapshot(collection(db, "teleScripts"), (snapshot) => {
      setTeleDocs([]);
      snapshot.forEach((teleDoc) => {
        setTeleDocs(
          (prev) =>
            [...prev, teleDoc.data()] as Array<{
              editor: RawDraftContentState;
              title: string;
              date: number;
              id: string;
            }>
        );
      });
    });
  }, []);

  const saveEditor = () => {
    const editorData = editorState.getCurrentContent();
    const raw = convertToRaw(editorData);
    if (currentScript) {
      const docRef = doc(db, `teleScripts/${currentScript}`);
      updateDoc(docRef, {
        editor: raw,
        title: currentTitle,
        date: new Date().getTime(),
      })
        .then(() => message.success("Saved"))
        .catch((err) => {
          message.error("Failed to save");
          console.error(err);
        });
      loadEditor(raw);
    }
  };

  const saveNewEditor = () => {
    const editorData = editorState.getCurrentContent();
    const raw = convertToRaw(editorData);
    const newId = nanoid();
    setCurrentScript(newId);
    const docRef = doc(db, `teleScripts/${newId}`);
    setDoc(docRef, {
      editor: raw,
      title: currentTitle,
      date: new Date().getTime(),
      id: newId,
    })
      .then(() => message.success("Saved"))
      .catch((err) => {
        message.error("Failed to save");
        console.error(err);
      });
    loadEditor(raw);
  };

  const scrollTo = () => {
    scrollRef.current && scrollRef.current.scrollBy({ top: 1 });
  };

  const scrollToTop = () => {
    scrollRef.current && scrollRef.current.scrollTo({ top: 1 });
  };

  const startScroll = () => {
    scrollInt.current = setInterval(() => {
      scrollTo();
    }, 1000 / scrollSpeed);
  };

  const clearScrollInt = () => clearInterval(scrollInt.current);

  const onBoldClick = () => {
    setEditorState(() => RichUtils.toggleCode(editorState));
  };

  const citations = [
    {
      id: "123",
      authors: ["Sanjana Hattotuwa", "Kate Hannah", "Kayli Taylor"],
      publishedYear: "2020",
      url: "http",
    },
    { id: "124", authors: ["Kate Hannah"], publishedYear: "2017", url: "http" },
  ];

  const citation = (props: any) => {
    const citeData = citations.filter(
      (cite) => cite.id === props.blockProps.test
    );
    return (
      <p style={{ color: "red" }}>
        {citeData.length > 0 &&
          `(${citeData[0].authors[0]}, ${citeData[0].publishedYear})`}
      </p>
    );
  };

  const myBlockRenderer = (block: ContentBlock) => {
    const type = block.getType();

    if (type === "code-block") {
      return {
        component: citation,
        editable: false,
        props: { test: block.getText() },
      };
    }
  };

  return (
    <div
      style={{
        height: "100%",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "black",
      }}
    >
      <Button
        style={{
          zIndex: 10,
          position: "absolute",
          top: "100px",
          left: "15px",
        }}
        icon={<SettingOutlined />}
        onClick={() => (isModal ? setIsModal(false) : setIsModal(true))}
      />
      <Button
        style={{
          zIndex: 10,
          position: "absolute",
          top: "150px",
          left: "15px",
        }}
        icon={<OrderedListOutlined />}
        onClick={() =>
          isCitations ? setIsCitations(false) : setIsCitations(true)
        }
      />
      {isEditMode ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: `${containerWidth}%`,
          }}
        >
          <div
            style={{
              height: "100%",
              overflowY: "scroll",
              width: `${100}%`,
              textAlign: textAlignState as CanvasTextAlign,
              backgroundColor: "white",
              padding: "2rem",
            }}
          >
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              blockStyleFn={(block) => myblockStyleFn(block)}
              blockRendererFn={(block) => myBlockRenderer(block)}
              spellCheck

              // keyBindingFn={(e) => {
              //   console.log(e.);
              //   return null;
              // }}
            />
          </div>
          {/* <div>
            <Button type="primary" onClick={() => onBoldClick()}>
              Change
            </Button>
          </div> */}
        </div>
      ) : (
        <div
          ref={scrollRef}
          style={{
            backgroundColor: "black",
            color: "white",
            transform: `scale(-1,1)`, //`scale(1,-1)`,
            paddingTop: "2%",
            paddingBottom: "2%",
            width: `${containerWidth}%`,
            overflowY: "scroll",
            textAlign: textAlignState as CanvasTextAlign,
          }}
          onLoad={(e) => e}
        >
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            blockStyleFn={(block) => myblockStyleFn(block)}
          />
        </div>
      )}

      <Modal
        visible={isModal}
        footer={null}
        title="Scripts"
        onCancel={() => setIsModal(false)}
      >
        <div
          style={{ color: "rgb(255,100,100)", marginBottom: ".5rem" }}
        >{`Current Script: ${currentTitle}`}</div>
        Available Scripts
        <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
          {teleDocs
            //.sort((a, b) => a.date - b.date)
            .map((teleDoc, idx) => {
              return (
                <Button
                  key={idx}
                  style={{ color: "black" }}
                  onClick={() => {
                    setEditorState(() =>
                      EditorState.createWithContent(
                        convertFromRaw(teleDoc.editor)
                      )
                    );
                    setCurrentScript(teleDoc.id);
                    setCurrentTitle(teleDoc.title);
                    setIsModal(false);
                  }}
                >
                  {teleDoc.title}
                </Button>
              );
            })}
        </div>
        <div style={{ marginTop: "1rem" }}>
          Title
          <Input
            value={currentTitle}
            onChange={(e) => setCurrentTitle(e.currentTarget.value)}
          />
          <Button onClick={() => saveEditor()}>Save</Button>
          <Button onClick={() => saveNewEditor()}>Save as new</Button>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <Button
            onClick={() =>
              isEditMode ? setIsEditMode(false) : setIsEditMode(true)
            }
          >
            {isEditMode ? "Edit Mode" : "Read Mode"}
          </Button>
          <Button
            onClick={() => {
              if (isScrolling) {
                clearScrollInt();
                setIsScrolling(false);
              } else {
                startScroll();
                setIsScrolling(true);
              }
            }}
          >
            {isScrolling ? "Stop" : "Start"}
          </Button>
          <Button onClick={() => scrollToTop()}>Return to Top</Button>
        </div>
        <div style={{ marginTop: ".5rem" }}>
          Width
          <Slider
            max={90}
            min={20}
            onChange={(e) => setContainerWidth(e)}
            value={containerWidth}
          />
        </div>
        <div>
          Speed
          <Slider
            max={200}
            min={10}
            onChange={(e) => setScrollSpeed(e)}
            value={scrollSpeed}
            dots
          />
        </div>
      </Modal>
      <Modal
        title="Citation Manager"
        visible={isCitations}
        onCancel={() => setIsCitations(false)}
      >
        <div
          style={{ height: "30vh", overflowY: "scroll", marginBottom: ".5rem" }}
        >
          {citations.map((cite, idx) => {
            let authors = "";
            if (cite.authors.length > 2) {
              authors = `${cite.authors[0]}, ${cite.authors[1]}, et. al.`;
            } else {
              authors = cite.authors[0];
            }

            return (
              <div key={idx}>
                <Button
                  size="small"
                  type="text"
                  onClick={() => {
                    navigator.clipboard.writeText(cite.id);
                    message.info("Copied to Clipboard");
                  }}
                >
                  {`${authors}. Published: ${cite.publishedYear}`}
                </Button>
              </div>
            );
          })}
        </div>

        <Select
          onChange={(v) => setSourceType(v)}
          style={{ width: "100%", marginBottom: ".5rem" }}
          defaultValue="journal"
        >
          <Select.Option value="journal">Journal</Select.Option>
          <Select.Option value="website">Website</Select.Option>
          <Select.Option value="image">Image</Select.Option>
          <Select.Option value="video">Video</Select.Option>
          <Select.Option value="book">Book</Select.Option>
        </Select>
        <div>
          <Form>
            {sourceType === "journal" && (
              <>
                <Form.Item>
                  <Input placeholder="Author" />
                </Form.Item>
                <Form.Item>
                  <Input placeholder="Article title" />
                </Form.Item>
                <Form.Item>
                  <Input placeholder="Journal Title" />
                </Form.Item>
                <Form.Item>
                  <YearPicker />
                </Form.Item>
              </>
            )}
            {sourceType === "website" && (
              <>
                <Form.Item>
                  <Input placeholder="Author" />
                </Form.Item>
                <Form.Item>
                  <Input placeholder="Website title" />
                </Form.Item>
                <Form.Item>
                  <DatePicker placeholder="Accessed" />
                </Form.Item>
              </>
            )}

            <Form.Item></Form.Item>
            <Form.Item></Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
