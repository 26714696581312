import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { Button, Dropdown, MenuProps } from "antd";
import { DownOutlined, PlusSquareOutlined } from "@ant-design/icons";

type Element = { type: string; content: string; id: string; position: number };

export const EleComp = ({
  sceneId,
  scriptId,
  sceneMap,
}: {
  sceneId: string;
  scriptId: string;
  sceneMap: Array<string>;
}) => {
  const [elementsArray, setElementsArray] = useState<Array<Element>>([]);
  const [activeEditEl, setActiveEditEl] = useState<null | string>(null);
  const [elId, setElId] = useState<null | string>(null);

  useEffect(() => {
    console.log(scriptId, sceneId);
    const unsub = onSnapshot(
      collection(db, `scripts/${scriptId}/scenes/${sceneId}/elements`),
      (sceneDocs) => {
        setElementsArray([]);
        sceneDocs.forEach((doc) => {
          setElementsArray((prev) => [
            ...prev,
            { ...doc.data(), id: doc.id } as Element,
          ]);
        });
        console.log({ elementsArray });
      }
    );

    return unsub;
  }, [sceneId, scriptId]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          size="small"
          type="text"
          onClick={() => setActiveEditEl("action")}
        >
          Action
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          size="small"
          type="text"
          onClick={() => setActiveEditEl("character")}
        >
          Character
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          size="small"
          type="text"
          onClick={() => setActiveEditEl("parenthesis")}
        >
          Parenthesis
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          size="small"
          type="text"
          onClick={() => setActiveEditEl("dialogue")}
        >
          Dialogue
        </Button>
      ),
    },
    {
      key: "5",
      label: (
        <Button
          size="small"
          type="text"
          onClick={() => setActiveEditEl("subScene")}
        >
          Sub Scene
        </Button>
      ),
    },
  ];

  //save and element and update the sceneMap

  return (
    <div
      style={{
        fontFamily: "monospace",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {elementsArray.length > 0 &&
        sceneMap.map((id, idx) => {
          const el = elementsArray.filter((ele) => ele.id === id)[0];
          if (el.type === "character") {
            return (
              <div
                key={idx}
                style={{
                  color: "black",
                  paddingTop: "2rem",
                }}
              >
                <div> {el.content.toUpperCase()}</div>
                <Dropdown menu={{ items }}>
                  <Button
                    type="text"
                    icon={<DownOutlined />}
                    onClick={() => setElId(el.id)}
                  />
                </Dropdown>
                {elId === el.id && <div>{el.id}</div>}
              </div>
            );
          } else if (el.type === "action") {
            return (
              <div
                key={idx}
                style={{
                  color: "black",
                  paddingTop: "1rem",
                  width: "60%",
                  textAlign: "left",
                }}
              >
                <div> {el.content}</div>
                <Dropdown menu={{ items }}>
                  <Button
                    type="text"
                    icon={<DownOutlined />}
                    onClick={() => setElId(el.id)}
                  />
                </Dropdown>
                {elId === el.id && <div>{el.id}</div>}
              </div>
            );
          } else if (el.type === "dialogue") {
            return (
              <div
                key={idx}
                style={{
                  color: "black",
                  paddingTop: "1rem",
                  width: "60%",
                  textAlign: "left",
                }}
              >
                <div> {el.content}</div>
                <Dropdown menu={{ items }}>
                  <Button
                    type="text"
                    icon={<PlusSquareOutlined />}
                    onClick={() => setElId(el.id)}
                  />
                </Dropdown>
                {elId === el.id && <div>{el.id}</div>}
              </div>
            );
          } else if (el.type === "parethesis") {
            return (
              <div
                key={idx}
                style={{
                  color: "black",
                  paddingTop: "1rem",
                  width: "60%",
                  textAlign: "left",
                }}
              >
                <div> {el.content}</div>
                <Dropdown menu={{ items }}>
                  <Button
                    type="text"
                    icon={<PlusSquareOutlined />}
                    onClick={() => setElId(el.id)}
                  />
                </Dropdown>
                {elId === el.id && <div>{el.id}</div>}
              </div>
            );
          } else if (el.type === "subScene") {
            return (
              <div
                key={idx}
                style={{
                  color: "black",
                  paddingTop: "1rem",
                  width: "60%",
                  textAlign: "left",
                }}
              >
                <div> {el.content}</div>
                <Dropdown menu={{ items }}>
                  <Button
                    type="text"
                    icon={<PlusSquareOutlined />}
                    onClick={() => setElId(el.id)}
                  />
                </Dropdown>
                {elId === el.id && <div>{el.id}</div>}
              </div>
            );
          } else {
            return (
              <div key={idx} style={{ color: "black" }}>
                <div> {el.content}</div>
                <Dropdown menu={{ items }}>
                  <Button
                    type="text"
                    icon={<PlusSquareOutlined />}
                    onClick={() => setElId(el.id)}
                  />
                </Dropdown>
                {elId === el.id && <div>{el.id}</div>}
              </div>
            );
          }
        })}
    </div>
  );
};
