import "firebase/auth";
import {
  IdTokenResult,
  ParsedToken,
  User,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { auth } from "../firebase";
import { AuthContextType } from "../typeDefs";

const AuthContext = React.createContext<AuthContextType>(null!);
export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: { children: any }) => {
  const goTo = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentRole, setCurrentRole] = useState<ParsedToken | null>(null);

  const signUp = async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            goTo("/administration-tools");
            return null;
          })
          .catch((err: any) => {
            console.error("Failed to login... Do you have an account?");
            return null;
          });
      })
      .catch((err: any) => console.error(err));
  };

  const login = (email: string, password: string) => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password)
      .then(
        () => {
          goTo("/tools");
          return true;
        },
        (err: any) => {
          console.error("Failed to login... Do you have an account?");
          return null;
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const logout = () => {
    return auth
      .signOut()
      .then(() => {
        goTo("/");
        setCurrentUser(null);
        setCurrentRole(null);
      })
      .catch((err: any) => console.error(err));
  };

  const sendPasswordReset = async (email: any) => {
    return await sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      if (!user) return;
      user
        .getIdTokenResult()
        .then((idTokenResult: IdTokenResult) => {
          // Confirm the user is an Admin.
          if (!!idTokenResult.claims) {
            // Show admin UI.
            setCurrentRole(idTokenResult.claims);
          } else {
            // Show regular user UI.
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    });
    return unsubscribe;
  }, []);

  const value = {
    loading,
    currentUser,
    signUp,
    login,
    logout,
    sendPasswordReset,
    currentRole,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
