import { atom } from "recoil";

export const contentCreatorImageState = atom<string | null>({
  key: "contentCreatorImageState",
  default: null,
});

export const contentCreatorAudioState = atom<string | null>({
  key: "contentCreatorAudioState",
  default: null,
});

export const contentCreatorAIState = atom<string | null>({
  key: "contentCreatorAIState",
  default: null,
});
