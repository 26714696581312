import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Upload,
} from "antd";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import moment from "moment";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { db, storage } from "../firebase";
import { FinanceEntryType } from "../typeDefs";
import dayjs from "dayjs";

const categories: { categories: any; options: any } = {
  categories: {
    Revenue: [
      "Sales Revenue",
      "Service Revenue",
      "Interest Income",
      "Dividend Income",
      "Rental Income",
    ],
    Expenses: [
      "Cost of Goods Sold",
      "Salaries and Wages",
      "Rent Expense",
      "Utilities Expense",
      "Depreciation Expense",
      "Interest Expense",
      "Insurance Expense",
      "Advertising Expense",
      "Subscriptions",
      "Travel and Entertainment",
      "Miscellaneous Expenses",
      "Professional Fees",
      "Maintenance and Repairs",
      "Research and Development",
      "Training and Development",
      "Office Supplies Expense",
    ],
    Assets: [
      "Cash and Cash Equivalents",
      "Accounts Receivable",
      "Inventory",
      "Prepaid Expenses",
      "Property, Plant, and Equipment",
      "Intangible Assets",
      "Investments",
      "Office Fit-Out",
      "Other Current Assets",
    ],
    Liabilities: [
      "Accounts Payable",
      "Short-term Debt",
      "Long-term Debt",
      "Accrued Liabilities",
      "Deferred Revenue",
      "Notes Payable",
    ],
    Equity: [
      "Common Stock",
      "Preferred Stock",
      "Retained Earnings",
      "Additional Paid-in Capital",
      "Treasury Stock",
    ],
  },
  options: {
    "Income Statement": [
      "Sales Revenue",
      "Service Revenue",
      "Cost of Goods Sold",
      "Salaries and Wages",
      "Rent Expense",
      "Utilities Expense",
      "Depreciation Expense",
      "Interest Income",
      "Interest Expense",
      "Advertising Expense",
      "Net Income",
    ],
    "Balance Sheet": [
      "Cash and Cash Equivalents",
      "Accounts Receivable",
      "Inventory",
      "Prepaid Expenses",
      "Property, Plant, and Equipment",
      "Intangible Assets",
      "Investments",
      "Accounts Payable",
      "Short-term Debt",
      "Long-term Debt",
      "Accrued Liabilities",
      "Deferred Revenue",
      "Notes Payable",
      "Common Stock",
      "Preferred Stock",
      "Retained Earnings",
      "Additional Paid-in Capital",
      "Treasury Stock",
    ],
    "Cash Flow Statement": [
      "Net Income",
      "Depreciation Expense",
      "Accounts Receivable",
      "Inventory",
      "Accounts Payable",
      "Accrued Liabilities",
      "Cash from Operating Activities",
      "Cash from Investing Activities",
      "Cash from Financing Activities",
    ],
    "Statement of Changes in Equity": [
      "Common Stock",
      "Preferred Stock",
      "Retained Earnings",
      "Additional Paid-in Capital",
      "Treasury Stock",
      "Net Income",
      "Dividends",
    ],
  },
};

export const Finances = ({
  activeItem,
}: {
  activeItem: FinanceEntryType | null;
}) => {
  const [activeCategory, setActiveCategory] = useState<string>("Revenue");
  const [imagePath, setImagePath] = useState<string | null>(null);
  const [gstSearch, setGstSearch] = useState<string>("");
  const [isGstRegistered, setIsGstRegistered] = useState<boolean>(true);
  const [isGstIncluded, setIsGstIncluded] = useState<boolean>(true);
  const [entryDate, setEntryDate] = useState<number>(Date.now());
  const [vendors, setVendors] = useState<
    Array<{ vendor: string; id: string; gstNumber: string }>
  >([]);

  const [entryForm] = Form.useForm();
  const [depreciationForm] = Form.useForm();
  const [apportionmentForm] = Form.useForm();

  useEffect(() => {
    if (!activeItem) {
      return;
    }

    setEntryDate(activeItem?.date);
    setImagePath(activeItem?.image);
    setIsGstRegistered(activeItem?.gstRegistered);
    setIsGstIncluded(activeItem?.gstIncluded);

    entryForm.setFieldsValue(activeItem);
  }, [activeItem]);

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "vendors"),
      (snapDoc) => {
        setVendors(
          snapDoc.docs.map(
            (doc) =>
              ({ ...doc.data(), id: doc.id } as {
                vendor: string;
                id: string;
                gstNumber: string;
              })
          )
        );
      },
      (err) => {
        console.error(err);
      }
    );
    return unsub;
  }, []);

  const handleAdEntry = () => {
    entryForm
      .validateFields()
      .then((values) => {
        console.log({ values });
        const newId = nanoid();
        const servicesDoc = doc(db, `/finances/${newId}`);
        setDoc(servicesDoc, {
          ...values,
          gstNumber: values.gstNumber ? values.gstNumber : null,
          date: entryDate,
          id: newId,
          image: imagePath,
          isDeleted: false,
          receipt: imagePath ? true : false,
        } as FinanceEntryType)
          .then(() => message.success("Saved new service."))
          .catch((err) => console.error(err));

        return values;
      })
      .then((values) => {
        if (!vendors.map((item) => item.vendor).includes(values.vendor)) {
          const vendorId = nanoid();
          const vendorDoc = doc(db, `/vendors/${vendorId}`);
          setDoc(vendorDoc, {
            id: vendorId,
            vendor: values.vendor,
            gstNumber: values.gstNumber,
          })
            .then(() => message.success("Vendors saved"))
            .catch((err) => console.error(err));
        }
      })
      .then(() => {
        entryForm.resetFields();
        setGstSearch("");
      })
      .catch((err) => {
        message.error(err.message);
        console.error(err);
      });
  };

  const handleEditEntry = () => {
    entryForm
      .validateFields()
      .then((values) => {
        updateDoc(doc(db, `finances/${activeItem?.id}`), {
          ...values,
          gstNumber: values.gstNumber ? values.gstNumber : null,
          date: entryDate,
          id: activeItem?.id,
          image: imagePath,
          isDeleted: false,
          receipt: imagePath ? true : false,
        } as FinanceEntryType)
          .then(() => message.success("Saved new service."))
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        message.error(err.message);
        console.error(err);
      });
  };

  const handleDepreciation = () => {
    depreciationForm
      .validateFields()
      .then((values) => {
        updateDoc(doc(db, `finances/${activeItem?.id}`), {
          ...values,
        } as FinanceEntryType)
          .then(() => message.success("Saved depreciation."))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const handleApportionment = () =>
    apportionmentForm
      .validateFields()
      .then((values) => {
        updateDoc(doc(db, `finances/${activeItem?.id}`), {
          apportionMethod: values.apportionMethod,
          apportionRate:
            values.apportionMethod === "Principal Method"
              ? 100
              : values.apportionMethod === "None"
              ? 0
              : values.apportionRate,
        } as FinanceEntryType)
          .then(() => message.success("Saved apportionment method."))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));

  const handleDeleteEntry = () => {
    updateDoc(doc(db, `finances/${activeItem?.id}`), {
      isDeleted: true,
    } as FinanceEntryType)
      .then(() => message.success("Deleted entry."))
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            color: "rgb(100,100,100)",
            fontFamily: "Oxanium",
            marginBottom: "3rem",
          }}
        >
          {activeItem ? "Edit Financial Entry" : "Add Financial Entry"}
        </h1>
        <div style={{ paddingBottom: "1rem" }}>
          {!activeItem && (
            <Button
              icon={<ReloadOutlined />}
              onClick={() => {
                setEntryDate(Date.now());
                setImagePath(null);
                setIsGstRegistered(true);
                setIsGstIncluded(true);
                entryForm.resetFields();
              }}
            />
          )}
        </div>
        <Form
          style={{ width: 350 }}
          form={entryForm}
          initialValues={{ gstNumber: "", ...activeItem }}
        >
          <Form.Item>
            <DatePicker
              defaultValue={dayjs(activeItem?.date)}
              onChange={(e) => {
                if (!e) return;
                setEntryDate(e.toDate().getTime());
              }}
            />
          </Form.Item>
          <Form.Item name="description">
            <Input placeholder="Description" />
          </Form.Item>
          <Form.Item name="gstNumber">
            <Input
              placeholder="GST Number"
              onChange={(e) => setGstSearch(e.target.value)}
            />
          </Form.Item>
          {gstSearch.length > 2 && vendors.length > 0 && (
            <div>
              {vendors
                .filter((item) => item.gstNumber?.includes(gstSearch))
                .map((item, idx) => (
                  <p
                    style={{ color: "rgb(200,200,200)" }}
                    onClick={() =>
                      entryForm.setFieldsValue({
                        gstNumber: item.gstNumber,
                        vendor: item.vendor,
                      })
                    }
                    key={idx}
                  >
                    {item.vendor}
                  </p>
                ))}
            </div>
          )}
          <Form.Item name="vendor">
            <Input placeholder="Other Party" />
          </Form.Item>

          <Form.Item name={"amount"}>
            <InputNumber placeholder="Amount" />
          </Form.Item>
          <Form.Item name="category">
            <Select
              placeholder="Category"
              onChange={(e) => setActiveCategory(e)}
            >
              {Object.keys(categories.categories).map((key, idx) => {
                return (
                  <Select.Option key={idx} value={key}>
                    {key}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="subCategory">
            <Select placeholder="Sub Category">
              {categories.categories[activeCategory].map(
                (key: string, idx: number) => {
                  return (
                    <Select.Option key={idx} value={key}>
                      {key}
                    </Select.Option>
                  );
                }
              )}
            </Select>
          </Form.Item>

          <Form.Item>
            <Checkbox
              style={{ color: "rgb(100,100,100)" }}
              onChange={() => setIsGstIncluded(!isGstIncluded)}
              checked={isGstIncluded}
            >
              Includes GST
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              style={{ color: "rgb(100,100,100)" }}
              onChange={() => setIsGstRegistered(!isGstRegistered)}
              checked={isGstRegistered}
            >
              GST Registered
            </Checkbox>
          </Form.Item>
          <Upload
            beforeUpload={(upload) => {
              const storageRef = ref(storage, `receipts/${upload.name}`);
              if (upload.type === "image/jpeg" || upload.type === "image/png") {
                uploadBytes(storageRef, upload)
                  .then((snapshot) => {
                    message.success("Uploaded file!");
                  })
                  .then(() => getDownloadURL(storageRef))
                  .then((url) => {
                    setImagePath(url);
                  })
                  .catch((err) => console.error(err));
              }
            }}
          >
            <Button>Select File</Button>
          </Upload>
          <div>
            {activeItem?.image && (
              <Image src={activeItem?.image} alt="Record.img" />
            )}
          </div>
          {activeItem ? (
            <>
              <Button
                style={{ marginTop: ".25rem" }}
                onClick={() => {
                  handleEditEntry();
                }}
              >
                Update entry
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  handleDeleteEntry();
                }}
              >
                <Button style={{ marginTop: ".25rem" }}>Delete entry</Button>
              </Popconfirm>
            </>
          ) : (
            <Button
              style={{ marginTop: ".25rem" }}
              onClick={() => {
                handleAdEntry();
              }}
            >
              Save entry
            </Button>
          )}
        </Form>
        {activeItem && activeItem.category === "Assets" && (
          <div
            style={{
              marginTop: "1rem",
              border: "1px solid grey",
              borderRadius: ".5rem",
              width: "350px",
              padding: ".5rem",
            }}
          >
            <div>Depreciation of Assets</div>
            <Form form={depreciationForm} style={{ width: "100%" }}>
              <Form.Item name="depreciationMethod">
                <Select
                  options={[
                    { value: "Straight Line", label: "Straight Line" },
                    { value: "Diminishing Value", label: "Diminishing Value" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="depreciationRate">
                <InputNumber placeholder="%" />
              </Form.Item>
            </Form>
            <Button
              onClick={() => {
                handleDepreciation();
              }}
            >
              Save
            </Button>
          </div>
        )}
        {activeItem &&
          (activeItem.category === "Expenses" ||
            activeItem.category === "Assets") && (
            <div
              style={{
                marginTop: "1rem",
                border: "1px solid grey",
                borderRadius: ".5rem",
                width: "350px",
                padding: ".5rem",
              }}
            >
              <div>Method of GST claim for expenses</div>
              <Form form={apportionmentForm} style={{ width: "100%" }}>
                <Form.Item name="apportionMethod">
                  <Select
                    options={[
                      { value: "Principal Method", label: "Principal Method" },
                      {
                        value: "Apportionment Method",
                        label: "Apportionment Method",
                      },
                      {
                        value: "None",
                        label: null,
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="apportionRate">
                  <InputNumber
                    max={100}
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="%"
                  />
                </Form.Item>
              </Form>
              <Button
                onClick={() => {
                  handleApportionment();
                }}
              >
                Save
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};
