import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";
import { Button, DatePicker, Form, Input, List, Select, message } from "antd";
import { useState } from "react";

type Package = {
  [standard: string]: {
    photos: { number: number; editing: string };
    video: { description: string };
  };
};
const packages: Package = {
  basic: {
    photos: { number: 10, editing: "basic edit" },
    video: { description: "Basic video of the house externals and internals" },
  },
  standard: {
    photos: { number: 20, editing: "moderately edited" },
    video: {
      description:
        "Basic video of the house externals and internals with additional drone video of house exterior",
    },
  },
  advanced: {
    photos: { number: 30, editing: "advanced edit" },
    video: {
      description:
        "Video of the house externals and internals with additional drone video of house exterior, Agent interview",
    },
  },
  highEnd: {
    photos: { number: 30, editing: "advanced edit" },
    video: {
      description:
        "Externals and Internals, drone video, Agent interview, house 3D modelling",
    },
  },
};

export const Customer = () => {
  const [realEstateForm] = Form.useForm();
  const { width } = useRecoilValue(windowSize);
  const [currentPackage, setCurrentPackage] = useState<null | string>(null);

  const handleRealEstateForm = () => {
    realEstateForm
      .validateFields()
      .then((values) => {
        console.log({ ...values, dueDate: new Date(values.dueDate).getTime() });
      })
      .catch((err) => message.error("Failed"));
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          maxWidth: "600px",
          padding: "3rem",
          backgroundColor: "rgba(200,200,200, .9)",
          borderRadius: "1rem",
          fontFamily: "oxanium",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            paddingBottom: "2rem",
            fontSize: width < 800 ? "3vw" : "1.5rem",
          }}
        >
          Real Estate Order Form
        </div>
        <Form form={realEstateForm}>
          <Form.Item name="jobReference">
            <Input placeholder="Job Reference" />
          </Form.Item>
          <Form.Item name="package">
            <Select
              placeholder="Package type"
              onChange={(value) => setCurrentPackage(value)}
            >
              <Select.Option value="basic">Basic</Select.Option>
              <Select.Option value="standard">Standard</Select.Option>
              <Select.Option value="highEnd">High End</Select.Option>
              <Select.Option value="advanced">Advanced</Select.Option>
            </Select>
          </Form.Item>

          {currentPackage &&
            Object.keys(packages)
              .filter((objKey) => objKey === currentPackage)
              .map((objKey, idx) => {
                return (
                  <List
                    key={idx}
                    header={`The ${currentPackage} package includes:`}
                  >
                    <List.Item>{`${packages[currentPackage].photos.number} ${packages[currentPackage].photos.editing} photos`}</List.Item>
                    <List.Item>{`${packages[currentPackage].video.description}`}</List.Item>
                  </List>
                );
              })}

          <Form.Item
            name="dueDate"
            rules={[
              {
                validator(rule_, value, callback) {
                  if (
                    new Date(value).getTime() >
                    new Date().getTime() + 86400000 * 4
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The due date cannot be withing four days")
                  );
                },
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Button onClick={() => handleRealEstateForm()}>Update</Button>
        </Form>
      </div>
    </div>
  );
};
