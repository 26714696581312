import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";

export const DocDisplay = () => {
    const { width, height } = useRecoilValue(windowSize);
    const loc = useLocation()
    
    return (
      <div
        style={{
          height: "100%",
          width: "100vw",
          position: "fixed",
          zIndex: "15",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {loc && (
            <embed
              //@ts-ignore
              src={loc.state.url}
              height={height}
              width={width}
            />
          )}
        </div>
      </div>
    );
}