import { Button, message } from "antd";
import { useEffect, useRef, useState } from "react";

export const AudioStream = ({ socketUrl }: { socketUrl: string }) => {
  const audioContext = useRef<AudioContext>(new AudioContext());
  const stream = useRef<MediaStream>();
  const streamSource = useRef<MediaStreamAudioSourceNode>();
  const socket = useRef<WebSocket>();
  var bufferSize = 1024 * 16;
  var processor = audioContext.current.createScriptProcessor(bufferSize, 1, 1);
  processor.connect(audioContext.current.destination);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    socket.current = new WebSocket(socketUrl);

    if (!socket.current) return;
    socket.current.binaryType = "arraybuffer";
    socket.current.addEventListener("open", () => {
      console.log("Connected to the server");
      setConnected(true);
    });

    socket.current.addEventListener("close", () => {
      console.log("Disconnected from the server");
      setConnected(false);
    });

    socket.current.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
    });

    return () => {
      socket.current?.close();
    };
  }, []);

  const startRecording = async () => {
    try {
      //get the microphone
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      console.log(stream.current.getTracks());
      streamSource.current = audioContext.current.createMediaStreamSource(
        stream.current
      );
      streamSource.current.connect(processor);
      processor.onaudioprocess = (e) => {
        microphoneProcess(e); // receives data from microphone
      };

      // mediaRecorder.current = new MediaRecorder(stream.current);

      // mediaRecorder.current.ondataavailable = (event) => {
      //   if (event.data.size > 0 && socket.current) {
      //     console.log(event.data.type);
      //     socket.current.send(event.data);
      //   }
      // };

      // mediaRecorder.onstop = () => {
      //   const audioBlob = new Blob(audioChunksRef.current, {
      //     type: "audio/wav",
      //   });
      //   const audioUrl = URL.createObjectURL(audioBlob);
      //   const audio = new Audio(audioUrl);
      //   audio.play();
      //   audioChunksRef.current = [];
      // };

      //  mediaRecorder.current.start(100);
    } catch (err) {
      console.error("Error accessing microphone:", err);
      message.error("Failed to stream");
    }
  };

  const microphoneProcess = (e: AudioProcessingEvent) => {
    console.log(e);
    const left = e.inputBuffer.getChannelData(0); // get only one audio channel

    socket.current?.send(left); // send to server via web socket
  };

  const stopRecording = () => {
    if (stream.current)
      if (processor) {
        if (streamSource.current) {
          try {
            streamSource.current.disconnect(processor);
          } catch (error) {
            console.warn("Attempt to disconnect input failed.");
          }
        }
        processor.disconnect(audioContext.current.destination);
      }
    if (audioContext) {
      audioContext.current.close().then(() => {
        audioContext.current = new AudioContext();
      });
    }
  };

  const listen = () => {
    if (socket.current)
      socket.current.addEventListener("message", (event) => {
        const int32Array = new Float32Array(event.data);
        const sampleRate = 48000;
        playInt32ArrayAsAudio(int32Array, sampleRate);
      });
  };

  async function playInt32ArrayAsAudio(
    float32Array: Float32Array,
    sampleRate: number
  ) {
    const audioContext = new AudioContext();

    // Create an AudioBuffer
    const audioBuffer = audioContext.createBuffer(
      1,
      float32Array.length,
      sampleRate
    );
    audioBuffer.copyToChannel(float32Array, 0);
    // Create a buffer source and play it
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start();
  }

  return (
    <div>
      {connected && (
        <>
          <Button onClick={() => startRecording()}>Stream</Button>
          <Button onClick={() => stopRecording()}>Stop</Button>
          <Button onClick={() => listen()}>Listen</Button>
        </>
      )}
    </div>
  );
};
