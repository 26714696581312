import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { Scene } from "../typeDefs";
import pdfMake from "pdfmake/build/pdfmake";

import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ScenePDF = ({ scene }: { scene: Scene }) => {
  const [costs, setCosts] = useState<number>(0);

  useEffect(() => {
    if (
      scene.transCost !== undefined &&
      scene.foodCost !== undefined &&
      scene.drinkCost !== undefined
    )
      return;
    //@ts-ignore
    setCosts(() => scene.transCost + scene.foodCost + scene.drinkCost);
  }, [scene]);

  const savePDF = () => {
    var dd = {
      watermark: {
        text: `SCRYPTWORX STUDIOS`,
        color: "dodgerblue",
        opacity: 0.1,
        bold: false,
        italics: false,
      },
      content: [
        {
          text: `SCENE PLAN`,
          style: "header",
        },
        "\n",
        { qr: scene.id, fit: 80 },
        { text: `Scene ID:`, style: ["small", "quote"] },
        { text: `${scene.id}\n`, style: ["small", "quote"] },
        "\n\n",
        {
          text: `${scene.sceneInOut?.toUpperCase()} ${scene.sceneLocation} - ${
            scene.sceneTime
          }.`,
          style: "subheader",
        },
        "\n\n",
        { text: `SHOOT DATES \n`, style: "subheader" },
        `From: ${
          scene.dates ? new Date(scene.dates[0]).toLocaleTimeString() : null
        } ${
          scene.dates ? new Date(scene.dates[0]).toLocaleDateString() : null
        }`,
        `To: ${
          scene.dates ? new Date(scene.dates[1]).toLocaleTimeString() : null
        } ${
          scene.dates ? new Date(scene.dates[1]).toLocaleDateString() : null
        }`,
        "\n\n",
        {
          text: "TALENT",
          style: "subheader",
        },
        { text: `ACTORS`, style: "small" },
        `${scene.actors}\n\n`,

        { text: `EXTRAS`, style: "small" },
        `${scene.extras}`,
        "\n\n",
        {
          text: "LOCATION",
          style: "subheader",
        },
        //{text: , style: ""},
        `${scene.locDescription}`,
        {
          text: `GPS: ${scene.gpsLat},${scene.gpsLong}\n\n`,
          style: ["small", "quote"],
        },
        `Point of contact: ${scene.contactPer}`,
        `Contact: ${scene.contactNum}\n\n`,
        `Facilities: ${scene.facilities}`,
        `Access: ${scene.access}`,
        `Admin area: ${scene.adminArea}`,
        `Special conditions: ${scene.conditions} \n\n`,
        `Power source: ${scene.power}`,
        "\n\n",
        {
          text: "SET DESIGN",
          style: "subheader",
        },
        `Period: ${scene.setPeriod}`,
        `Colour palette: ${scene.colourPalette}\n\n`,
        { text: `TEXTURING and DETAIL`, style: "small" },
        `${scene.setTexAndDetail} \n\n`,
        { text: `CONTUNUITY`, style: "small" },
        `${scene.setContinuity}`,
        "\n\n",
        {
          text: "ART DESIGN",
          style: "subheader",
        },
        { text: `PROPS - SET`, style: "small" },
        `${scene.propsSet}\n\n`,
        { text: `PROPS - ACTORS`, style: "small" },
        `${scene.propsActors}\n\n`,
        { text: `COSTUMES - ACTORS`, style: "small" },
        `${scene.costumesMain}\n\n`,
        { text: `COSTUMES - EXTRAS`, style: "small" },
        `${scene.costumeExtras}`,
        "\n\n",
        {
          text: "FIREARMS PLAN",
          style: "subheader",
        },
        {
          text: `${
            scene.firearmsRequired
              ? "FIREARMS ARE USED IN THIS SCENE\n\n"
              : "No firearms are used in this scene."
          }`,
          style: { color: scene.firearmsRequired ? "red" : "black" },
        },
        "\n\n",
        {
          text: "VFX PLAN",
          style: "subheader",
        },
        {
          text: `${
            scene.pyro
              ? "PYROTECHNICS ARE USED IN THIS SCENE"
              : "No pyrotechnics are used in this scene."
          }`,
          style: { color: scene.pyro ? "red" : "black" },
        },
        `Background type: ${scene.chromaKeyScreens}`,
        `${scene.atmosphere ? "A smoke generator is required." : ""}`,
        `${scene.day4night ? "This is a DAY-4-NIGHT scene\n\n" : "\n\n"}`,
        { text: `MINITURES`, style: "small" },
        `${scene.minitures}\n\n`,
        { text: `CGI and 3D`, style: "small" },
        `${scene.cgi}`,
        "\n\n",

        {
          text: "CAMERA PLAN",
          style: "subheader",
        },
        { text: `A CAMERA`, style: "small" },
        `${scene.aCamera}`,
        `${scene.aCameraPower}`,
        `${scene.aCameraSupport}\n\n`,
        { text: `B CAMERA`, style: "small" },
        `${scene.bCamera}`,
        `${scene.bCameraPower}`,
        `${scene.bCameraSupport}\n\n`,
        { text: `AERIAL CAMERA`, style: "small" },
        `${scene.droneRequired ? scene.drone : "Not required"}\n\n`,
        { text: `UNDERWATER CAMERA`, style: "small" },
        `${scene.underwaterRequired ? scene.uwCamera : ""}`,
        `${scene.underwaterRequired ? scene.uwPlan : ""}`,
        "\n\n",
        {
          text: "CAMERA MOVEMENT PLAN",
          style: "subheader",
        },
        `${scene.movePlan}\n\n`,
        { text: `EQUIPMENT`, style: "small" },
        `${scene.dollyRequired ? "Dolly required" : ""}`,
        `${scene.craneRequired ? "Crane required" : ""}`,
        `${scene.steadycamRequired ? "Steadycam required" : ""}`,
        `${scene.gimbalRequired ? "Gimbal required" : ""}`,
        "\n\n",
        {
          text: "LIGHTING PLAN",
          style: "subheader",
        },
        { text: `MAIN LIGHTS`, style: "small" },
        { text: `PRACTICAL LIGHTS`, style: "small" },
        "\n\n",
        {
          text: "SOUND and SFX PLAN",
          style: "subheader",
        },
        { text: `CAPTURE`, style: "small" },
        `${scene.soundCapture}\n\n`,
        { text: `INTERFERENCE`, style: "small" },
        `${scene.soundInterference}\n\n`,
        { text: `SFX`, style: "small" },
        `Music: ${scene.music}`,
        `Foley on set: ${scene.foleyOnScene}`,
        `Foley in post: ${scene.foleyAfter}`,
        "\n\n",
        {
          text: "TRANSPORT",
          style: "subheader",
        },
        `To and from set: ${scene.transport}`,
        `Vehicle hire: ${scene.transportHire}`,
        "\n\n",
        {
          text: "CATERING",
          style: "subheader",
        },
        `${scene.food}`,
        `${scene.drink}`,
        "\n\n",
        {
          text: "Expenses",
          style: "subheader",
        },
        `${costs}`,
      ],
      //   defaultStyle: {
      //     font: "fontLight",
      //   },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
        },
        subheader: {
          fontSize: 14,
          bold: true,
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 8,
        },
        bold: { bold: true },
      },
    };

    pdfMake.createPdf(dd).open();

    message.success("Printing");
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          savePDF();
        }}
      >
        Download PDF
      </Button>
    </div>
  );
};
