import {
  ControlOutlined,
  LoadingOutlined,
  LoginOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Image, Spin } from "antd";
import { DocumentData, collection, onSnapshot } from "firebase/firestore";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import Particles from "react-particles";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import type { Container, Engine } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";
import { AudioAnalyser } from "../AudioAnalyser/AudioAnalyser";
import { AudioStream } from "../AudioStream/AudioStream";
import { useAuth } from "../AuthProvider/AuthProvider";

import { CameraControl } from "../CameraControl/CameraControl";
import { ClapperBoard } from "../ClapperBoard/ClapperBoard";
import { ColorPalette } from "../ColorPalette/ColorPalette";
import { Contact } from "../Contact/Contact";
import { Customer } from "../Customer/Customer";
import { CustomerRoute } from "../CustomerRoute/CustomerRoute";
import { DocDisplay } from "../DocDisplay/DocDisplay";
import { Donate } from "../Donate/Donate";
import { PaymentComplete } from "../Donate/PaymentComplete";
import { FileUpload } from "../FileUpload/FileUpload";
import { Transcribe } from "../FileUpload/Transcribe";
import { FinanceLibrary } from "../Finances/FinanceLibrary";
import { FinanceReports } from "../Finances/FinanceReports";
import { Invoices } from "../Invoices/Invoices";
import { Library } from "../Library/Library";
import { Live } from "../Live/Live";
import { Login } from "../Login/Login";
import { MapTool } from "../MapTool/MapTool";
import { MemberRoute } from "../MemberRoute/MemberRoute";
import { NoAccess } from "../NoAccess/NoAccess";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { Projects } from "../Projects/PublicProjects";
import { ScenePlan } from "../SceneElement/ScenePlan";
import { AIScriptWriter } from "../ScriptWriter/AIScriptWriter";
import { Services } from "../Services/Services";
import { Tasks } from "../Tasks/Tasks";
import { Teleprompter } from "../Teleprompter/Teleprompter";
import { db } from "../firebase";
import { publicProjectsState, windowSize } from "../recoil/recoil";
import { PublicProjectType } from "../typeDefs";
import logo from "./../Images/SCRYPTWORX STUDIOS Slim.png";
import bg from "./Network.png";
import { CreateEvent } from "../Tasks/CreateEvent";
import { Events } from "../Tasks/Events";
import AIAnalyser from "../AIAssist/AIAnalyser";

const ScriptWriter = React.lazy(() => import("../ScriptWriter/ScriptWriter"));
const Tools = React.lazy(() => import("../Tools/Tools"));
const ProjectAdmin = React.lazy(() => import("../ProjectAdmin/ProjectAdmin"));
const ScriptCreator = React.lazy(
  () => import("../ScriptCreator/ScriptCreator")
);
const WebsiteManagement = React.lazy(
  () => import("../WebsiteManagement/WebsiteManagement")
);

const locations = [
  {
    longitude: 174.77614466019273,
    latitude: -41.28675449342123,
    timeInSeconds: 5,
    description: null,
  },
  {
    longitude: -0.1263591426023874, //,
    latitude: 51.50099650499141,
    timeInSeconds: 4,
    description: "London",
  },
  {
    longitude: -98.01219275222266, //,
    latitude: 30.532649290713778,
    timeInSeconds: 6,
    description: "USA",
  },
  {
    longitude: 35.068456980381924, //,
    latitude: 31.921969854678512,
    timeInSeconds: 7,
    description: "Israel",
  },
  {
    longitude: 52.837506569212614, //,
    latitude: 33.98931756393797,
    timeInSeconds: 5,
    description: "Iran",
  },
  {
    longitude: 174.77614466019273,
    latitude: -41.28675449342123,
    timeInSeconds: 8,
    description: "Centrist.nz",
  },
];

export const Foreground = () => {
  const [publicProjects, setPublicProjects] =
    useRecoilState(publicProjectsState);
  const [socketUrl, setSocketUrl] = useState<null | DocumentData>(null);
  const { width, height } = useRecoilValue(windowSize);
  // const spinIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
  const nav = useNavigate();
  const loc = useLocation();
  const { currentUser, logout } = useAuth();

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "publicProjects"),
      (snap) => {
        setPublicProjects([]);
        snap.docs.forEach((doc) => {
          setPublicProjects((prev) => [
            ...prev,
            doc.data() as PublicProjectType,
          ]);
        });
      },
      (err) => console.error(err)
    );
    return unsub;
  }, [setPublicProjects]);

  // useEffect(() => {
  //   getDoc(doc(db, "socket/hwlOdYXgUA1JIimwuugX"))
  //     .then((snapDoc) => {
  //       if (snapDoc.data()) {
  //         setSocketUrl(snapDoc.data() || null);
  //       }
  //     })
  //     .catch((err) => console.error(err));
  // }, []);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await console.log("p l");
    },
    []
  );

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundImage: `url(${bg})`,
        //background: `radial-gradient(circle, rgba(124,206,255,0.2861519607843137) 0%, rgba(0,35,51,0.7287289915966386) 100%)`, //`linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          top: 0,
          left: 0,
          overflow: "hidden",
          zIndex: 3,
        }}
      >
        <Particles
          id="tsparticles"
          options={{
            preset: "snow",
            background: {
              color: {
                value: "rgba(30,30,30,.2)",
              },
              opacity: 0.2,
            },
            fpsLimit: 120,

            particles: {
              color: {
                value: "rgb(255,255,255)", //"radial-gradient(circle, rgba(255,216,137,1) 28%, rgba(255,224,162,0) 100%)", //
              },
              links: {
                color: "rgb(255,255,255)",
                distance: 150,
                enable: true,
                opacity: 0.1,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: true,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.1,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 1, max: 10 },
              },
            },
            detectRetina: true,
          }}
          init={particlesInit}
          loaded={particlesLoaded}
        />
      </div>
      {loc.pathname === "/" ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "rgba(13,11,4,0.)",
              boxShadow: "5px 3px 10px 10px rgba(0,0,0,0.5)",
              padding: "1rem",
              borderRadius: "1rem",
              backdropFilter: "blur(1rem)",
              width: "100%",
              zIndex: "15",
            }}
          >
            <Image
              src={logo}
              style={{ width: "7vw", minWidth: "70px" }}
              preview={false}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Button
                type="link"
                style={{
                  fontFamily: "Oxanium",
                  color: "white",
                }}
                onClick={() => {
                  nav("/services");
                }}
              >
                Services
              </Button>
              {/* <Button
                type="link"
                style={{
                  fontFamily: "Oxanium",
                  color: "white",
                }}
                onClick={() => {
                  nav("/projects");
                }}
              >
                Projects
              </Button> */}
              <Button
                type="link"
                style={{
                  fontFamily: "Oxanium",
                  color: "white",
                }}
                onClick={() => {
                  nav("/contact");
                }}
              >
                Contact Us
              </Button>
              <div>
                {currentUser ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="text"
                      style={{
                        fontFamily: "Oxanium",
                        color: "white",
                      }}
                      onClick={() => {
                        nav("/tools");
                      }}
                      icon={<ControlOutlined />}
                    />
                    <Button
                      type="text"
                      style={{ fontFamily: "oxanium", color: "white" }}
                      onClick={() => {
                        logout();
                      }}
                      icon={<LogoutOutlined />}
                    />
                  </div>
                ) : (
                  <Button
                    type="text"
                    style={{ fontFamily: "oxanium", color: "white" }}
                    onClick={() => nav("/login")}
                    icon={<LoginOutlined />}
                  />
                )}
              </div>
            </div>
          </div>
          {currentUser && (
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<Spin size="large" />}>
                      <Events />
                    </Suspense>
                  </PrivateRoute>
                }
              />
            </Routes>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              position: "fixed",
              bottom: "0",
              width: "100%",
              height: "7vh",
              maxHeight: "100px",
              zIndex: "15",
              justifyContent: "space-between",
              backgroundColor: "rgba(13,11,4,0.2)",
              boxShadow: "5px 3px 10px 10px rgba(0,0,0,0.5)",
              padding: "1rem",
              borderRadius: "1rem",
              backdropFilter: "blur(1rem)",
            }}
          ></div>
        </>
      ) : (
        // </div>
        <div
          style={{
            height: "100vh",
            width: "100vw",

            zIndex: "15",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Routes>
            <Route
              path="/services"
              element={
                <Suspense fallback={<Spin size="large" />}>
                  <Services />
                </Suspense>
              }
            />
            <Route
              path="/jtre89t043tjkndrfkjgnkiuu8e98ou984utjiojrtklnetker"
              element={
                <Suspense fallback={<Spin size="large" />}>
                  <FileUpload />
                </Suspense>
              }
            />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/livestream/:name/:key"
              element={
                <Suspense fallback={<Spin size="large" />}>
                  <Live />
                </Suspense>
              }
            />
            <Route path="/unauthorised-access" element={<NoAccess />} />
            <Route
              path="/tools"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <Tools />
                  </Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path="/transcribe"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <Transcribe />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/tasks"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <Tasks />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/ai-tools"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <AIAnalyser />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/audio-stream"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    {socketUrl ? (
                      <AudioStream socketUrl={socketUrl.url} />
                    ) : (
                      <div>No Socket</div>
                    )}
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/colour-palette"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <ColorPalette />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/calendar"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <CreateEvent />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/audio-analyser"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <AudioAnalyser />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/map-tool"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <MapTool locations={locations} />
                    {/* <CanvasVideo /> */}
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/finance-library"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <FinanceLibrary />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/finance-reports"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <FinanceReports />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/clapperboard"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <ClapperBoard />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/script-creator"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <ScriptCreator />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/project-admin/:projectid"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <ProjectAdmin />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/script-writer/:scriptid"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <AIScriptWriter />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/scene-planning/:sceneid"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <ScenePlan />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/website-management"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <WebsiteManagement />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/donate"
              element={
                <MemberRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <Donate />
                  </Suspense>
                </MemberRoute>
              }
            />
            <Route
              path="/camera-control"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <CameraControl />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/invoices"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <Invoices />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/library"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <Library />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/library/doc"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <DocDisplay />
                  </Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path="/teleprompter"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <Teleprompter />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/thanks"
              element={
                <PrivateRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <PaymentComplete />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/order"
              element={
                <CustomerRoute>
                  <Suspense fallback={<Spin size="large" />}>
                    <Customer />
                  </Suspense>
                </CustomerRoute>
              }
            />
          </Routes>
        </div>
      )}
      {/* <Footer height={height} /> */}
    </div>
  );
};
