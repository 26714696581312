import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { atom, atomFamily, selector } from "recoil";
import { db } from "../firebase";
import {
  ClapperData,
  ProjectArray,
  ProjectType,
  PublicProjectArray,
  Script,
} from "../typeDefs";

export const windowSize = atom({
  key: "windowState",
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  effects: [
    ({ setSelf }) => {
      const listener = () => {
        setSelf({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener("resize", listener);
      return () => {
        window.removeEventListener("resize", listener);
      };
    },
  ],
});

export const projectsState = atom<ProjectArray>({
  key: "projectsStateKey",
  default: [] as ProjectArray,
});

export const publicProjectsState = atom<PublicProjectArray>({
  key: "publicProjectsStateKey",
  default: [] as PublicProjectArray,
});

export const activeProjectState = atom<ProjectType>({
  key: "activeProjectStateKey",
});

export const currentScriptState = atomFamily<null | Script, string>({
  key: "currentScriptStateKey",
  default: null,
  effects: (scriptId) => [
    ({ setSelf }) => {
      const unsub = onSnapshot(
        doc(db, `scripts/${scriptId}`),
        (scriptDoc) => {
          const script = scriptDoc.data() as Script;
          console.log("recoil", { script });
          setSelf(script);
        },
        (err) => console.error(err)
      );
      return unsub;
    },
  ],
});

export const scriptState = selector<Script | null>({
  key: "scriptStateKey",
  get: ({ get }) => {
    const activeProject = get(activeProjectState);
    if (!activeProject?.scriptId) return null;
    return get(currentScriptState(activeProject.scriptId));
  },
  set: ({ get }, value) => {
    const activeProject = get(activeProjectState);
    if (!activeProject?.scriptId) return;
    setDoc(doc(db, `scripts/${activeProject?.scriptId}/scenes/${nanoid()}`), {
      elements: value,
      time: new Date().getTime(),
    });
  },
});

// export const scriptState = atom<Script | null>({
//   key: "windowState",
//   default: null,
//   effects: [
//     ({ setSelf }) => {
//       const listener = () => {
//         setSelf({
//           width: window.innerWidth,
//           height: window.innerHeight,
//         });
//       };
//       window.addEventListener("resize", listener);
//       return () => {
//         window.removeEventListener("resize", listener);
//       };
//     },
//   ],
// });

export const cameraState = atom<any>({
  key: "cameraStateKey",
  default: null,
});

export const scriptStateCurrent = atom<Array<Script>>({
  key: "scriptStateNewKey",
  default: [],
});

export const clapperState = atom<ClapperData>({
  key: "clapperStateKey",
  default: {
    title: "TITLE",
    startTime: new Date().getTime(),
    scale: 1,
    scene: 1,
    take: 1,
  },
  effects: [
    ({ setSelf }) => {
      const unsub = onSnapshot(
        doc(db, "clapper/4ofv60qFSlgMrJZXFV8h"),
        (snapDoc) => {
          setSelf(snapDoc.data() as ClapperData);
        }
      );

      return unsub;
    },
  ],
});
