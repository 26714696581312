import { ControlOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, Modal, message } from "antd";
import { useRef, useState } from "react";
import Masonry from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { cameraState } from "../recoil/recoil";
import { BluetoothDevice, BluetoothRemoteGATTService } from "../typeDefs";

type ParamType = {
  param: string;
  label: string;
  inputType: string | null;
  func: Function;
  dataType: string | null;
  value: any;
  color: string;
  bgColor?: string;
};

export const CameraControl = () => {
  const nav = useNavigate();
  const [connectModal, setConnectModal] = useState<boolean>(false);
  //const [kelvins, setKelvins] = useState<number>(3000);
  const cam = useRef<BluetoothDevice>();
  const camService = useRef<BluetoothRemoteGATTService>();
  const camControl: any = useRef(null);
  const [status, setStatus] = useState({
    message: "Disconnected",
    color: "rgb(255,150,150)",
  });
  const [paramState, setParamState] = useState<ParamType | null>(null);
  const [valueState, setValueState] = useState<any>(null);
  const [inputForm] = Form.useForm();
  const [camState, setCamState] = useRecoilState(cameraState);

  const connectToCamera = (value: any) => {
    if (!navigator.bluetooth) return;

    const nav: Bluetooth = navigator.bluetooth;
    if (nav) {
      nav
        .requestDevice({
          filters: [
            {
              services: [
                "00001800-0000-1000-8000-00805f9b34fb",
                "291d567a-6d75-11e6-8b77-86f30ca893d3",
              ],
            },
          ],
        })
        .then((avail) => {
          setCamState(avail);
          setStatus({
            message: `Camera available: ${avail.name}`,
            color: "dodgerblue",
          });
          cam.current = avail;
        })
        .then(() => {
          setStatus({ message: "Connecting...", color: "gold" });
          if (!cam.current) return;
          if (cam.current.gatt) {
            return cam.current.gatt.connect();
          }
        })
        .then(() => {
          setStatus({ message: "Getting service...", color: "gold" });
          return camState.gatt.getPrimaryService(
            "291d567a-6d75-11e6-8b77-86f30ca893d3"
          );
        })
        .then((service) => {
          camService.current = service;

          //message.info("Camera Ready");
          setStatus({ message: `Connected`, color: "rgb(100, 255, 100)" });
          setConnectModal(false);
        })
        .catch(() => {
          message.error("Failed to Connect");
          setStatus({
            message: "Disconnected",
            color: "rgb(255,150,150)",
          });
        });
    } else {
      message.error("No Bluetooth");
    }
  };

  const setWB = async (value: any) => {
    //console.log(`White Balance Update: ${valueState}`);
    if (!camService.current) return;
    camService.current
      .getCharacteristic("5dd3465f-1aee-4299-8493-d2eca2f8e1bb")
      .then((serv: any) => {
        camControl.current = serv;
        const myInt = new Int16Array([value]);
        const view = new DataView(myInt.buffer);
        const posOne = view.getInt8(0);
        const posTwo = view.getInt8(1);
        const commandWBK = new Uint8Array([
          255,
          6,
          0,
          0,
          1,
          2,
          2,
          0,
          posOne,
          posTwo,
          0,
          0,
        ]);
        camControl.current.writeValue(commandWBK);
      })
      .catch((err: any) => console.error(err));
  };

  const disconnectCam = (value: any) => {
    if (!cam.current) return;
    if (cam.current.gatt) cam.current.gatt.disconnect();
    setStatus({ message: "Disconnected!", color: "rgb(255,150,150)" });
  };

  const framePS = (value: any) => {
    if (!camService.current) return;
    camService.current
      .getCharacteristic("5dd3465f-1aee-4299-8493-d2eca2f8e1bb")
      .then((serv: any) => {
        const commandFPS = new Uint8Array([
          255,
          9,
          0,
          0,
          1,
          0,
          1,
          0,
          value,
          0,
          6,
          0,
          0,
          0,
          0,
          0,
        ]);
        serv.writeValue(commandFPS);
        message.success("Updated");
      })
      .catch((err: any) => console.error(err));
  };

  const displayLUT = (value: any) => {
    if (!camService.current) return;
    camService.current
      .getCharacteristic("5dd3465f-1aee-4299-8493-d2eca2f8e1bb")
      .then((serv: any) => {
        const commandLUT = new Uint8Array([
          255,
          7,
          0,
          0,
          1,
          15,
          1,
          0,
          value,
          1,
          0,
          0,
        ]);
        serv.writeValue(commandLUT);
        message.success("Updated LUT");
        console.log(value);
      })
      .catch((err: any) => console.error(err));
  };

  const readInfo = (value: any) => {
    // camService.current
    //   .getCharacteristic("7fe8691d-95dc-4fc5-8abd-ca74339b51b9").then((character) => {
    //     character
    //   })
  };

  const setFocusAssist = (value: any) => {
    if (!camService.current) return;
    camService.current
      .getCharacteristic("5dd3465f-1aee-4299-8493-d2eca2f8e1bb")
      .then((serv: any) => {
        const commandFA = new Uint8Array([
          255,
          6,
          0,
          0,
          4,
          5,
          1,
          0,
          1,
          value,
          0,
          0,
        ]);
        serv.writeValue(commandFA);
        message.error("Focus Assist");
      });
  };

  const overlays = (value: any) => {
    if (!camService.current) return;
    camService.current
      .getCharacteristic("5dd3465f-1aee-4299-8493-d2eca2f8e1bb")
      .then((serv: any) => {
        const commandO = new Uint8Array([
          255,
          8,
          0,
          0,
          3,
          3,
          1,
          0,
          value,
          30,
          90,
          0,
        ]);
        serv.writeValue(commandO);
        message.info("Overlay");
      });
  };

  const setISO = (value: any) => {
    if (!camService.current) return;
    camService.current
      .getCharacteristic("5dd3465f-1aee-4299-8493-d2eca2f8e1bb")
      .then((serv: any) => {
        const myInt = new Int32Array([value]);
        const view = new DataView(myInt.buffer);

        const commandISO = new Uint8Array([
          255,
          8,
          0,
          0,
          1,
          14,
          3,
          0,
          view.getInt8(0),
          view.getInt8(1),
          view.getInt8(2),
          view.getInt8(3),
          0,
          0,
          0,
          0,
        ]);
        serv.writeValue(commandISO);
        message.success(`ISO ${value}`);
      });
  };

  const record = (value: any) => {
    if (!camService.current) return;
    camService.current
      .getCharacteristic("5dd3465f-1aee-4299-8493-d2eca2f8e1bb")
      .then((serv: any) => {
        const commandStart = new Uint8Array([
          255, 9, 0, 0, 10, 1, 1, 0, 2, 0, 0, 3, 1, 0, 0, 0,
        ]);
        serv.writeValue(commandStart);
        message.error("Recording");
      });
  };

  const stop = (value: any) => {
    if (!camService.current) return;
    camService.current
      .getCharacteristic("5dd3465f-1aee-4299-8493-d2eca2f8e1bb")
      .then((serv: any) => {
        const commandStart = new Uint8Array([
          255, 9, 0, 0, 10, 1, 1, 0, 0, 0, 0, 3, 1, 0, 0, 0,
        ]);
        serv.writeValue(commandStart);
        message.success("Stopped");
      });
  };

  const buttonStyle = {
    border: "1px solid rgb(200,200,200)",
    borderRadius: ".25rem",
    //backgroundColor: "rgb(230,230,230)",
    //color: "rgb(70,70,70)",
    borderColor: "rgb(200,200,200)",
    padding: ".5rem",
    cursor: "pointer",
  };

  const params = [
    {
      param: "connect",
      label: "Connect",
      inputType: null,
      func: connectToCamera,
      dataType: null,
      value: null,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "record",
      label: "Record",
      inputType: null,
      func: record,
      dataType: "preset",
      value: null,
      color: "rgb(230,230,230)",
      bgColor: "rgb(255,100,100)",
    },
    {
      param: "disconnectCam",
      label: "Disconnect",
      inputType: null,
      func: disconnectCam,
      dataType: null,
      value: null,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "25Frames",
      label: "25 fps",
      inputType: null,
      func: framePS,
      dataType: "preset",
      value: 25,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "stop",
      label: "Stop",
      inputType: null,
      func: stop,
      dataType: "preset",
      value: null,
      color: "rgb(230,230,230)",
      bgColor: "rgb(255,150,50)",
    },
    {
      param: "50Frames",
      label: "50 fps",
      inputType: null,
      func: framePS,
      dataType: "preset",
      value: 50,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "LUT",
      label: "Film to Ext Vid",
      inputType: null,
      func: displayLUT,
      dataType: "preset",
      value: 3,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "LUT2",
      label: "Film to Vid",
      inputType: null,
      func: displayLUT,
      dataType: "preset",
      value: 2,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "LUT3",
      label: "No LUT",
      inputType: null,
      func: displayLUT,
      dataType: "preset",
      value: 0,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "read",
      label: "INFO",
      inputType: null,
      func: displayLUT,
      dataType: "preset",
      value: null,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "whiteBalance25",
      label: "2500K",
      inputType: null,
      func: setWB,
      dataType: "int16",
      value: 2500,
      color: "rgb(70,70,70)",
      bgColor: "rgb(255,180,50)",
    },
    {
      param: "whiteBalance30",
      label: "3000K",
      inputType: null,
      func: setWB,
      dataType: "int16",
      value: 3000,
      color: "rgb(70,70,70)",
      bgColor: "rgb(255,220,100)",
    },
    {
      param: "whiteBalance45",
      label: "4500K",
      inputType: null,
      func: setWB,
      dataType: "int16",
      value: 4500,
      color: "rgb(70,70,70)",
      bgColor: "rgb(255,240,230)",
    },
    {
      param: "whiteBalance56",
      label: "5600K",
      inputType: null,
      func: setWB,
      dataType: "int16",
      value: 5600,
      color: "rgb(70,70,70)",
      bgColor: "rgb(240,250,255)",
    },
    {
      param: "whiteBalance60",
      label: "6000K",
      inputType: null,
      func: setWB,
      dataType: "int16",
      value: 6000,
      color: "rgb(70,70,70)",
      bgColor: "rgb(200,230,255)",
    },
    {
      param: "iso400",
      label: "ISO 400",
      inputType: null,
      func: setISO,
      dataType: "int32",
      value: 400,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "iso1250",
      label: "ISO 1250",
      inputType: null,
      func: setISO,
      dataType: "int32",
      value: 1250,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "iso2500",
      label: "ISO 2500",
      inputType: null,
      func: setISO,
      dataType: "int32",
      value: 2500,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "focusAssistG",
      label: "Focus Assist",
      inputType: null,
      func: setFocusAssist,
      dataType: "int8",
      value: 1,
      color: "rgb(70,200,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "focusAssistB",
      label: "Focus Assist",
      inputType: null,
      func: setFocusAssist,
      dataType: "int8",
      value: 2,
      color: "rgb(70,100,255)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "focusAssistR",
      label: "Focus Assist",
      inputType: null,
      func: setFocusAssist,
      dataType: "int8",
      value: 0,
      color: "rgb(230,100,100)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "overlay235",
      label: "2.35",
      inputType: null,
      func: overlays,
      dataType: "int8",
      value: 3,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
    {
      param: "overlay169",
      label: "16:9",
      inputType: null,
      func: overlays,
      dataType: "int8",
      value: 5,
      color: "rgb(70,70,70)",
      bgColor: "rgb(230,230,230)",
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgba(30,30,30,.8)",
      }}
    >
      <div
        style={{
          width: "100vw",
          //height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "500px",
            width: "95%",
            alignSelf: "center",
            backgroundColor: "rgba(40,40,40,.8)",
            padding: "1rem",
            borderRadius: ".25rem",
            boxShadow: "0 5px 10px rgb(20,20,20)",
          }}
        >
          <div
            style={{
              fontFamily: "oxanium",
              color: "whitesmoke",
              paddingBottom: "1rem",
            }}
          >
            BMPCC6K Control
          </div>
          <Image
            src="https://firebasestorage.googleapis.com/v0/b/scryptworxstudios.appspot.com/o/images%2Fblackmagic-cinema-camera-6k.png?alt=media&token=2e22caf0-e9f3-4a0a-9a1a-f12e7a87f040"
            style={{ maxWidth: "200px", alignSelf: "center" }}
            preview={false}
          />
          <div
            style={{
              color: status.color,
              fontFamily: "oxanium",
              paddingBottom: "1rem",
            }}
          >
            {status.message}
          </div>
          <Masonry columnsCount={3} gutter=".5rem">
            {params.map((param, idx) => {
              return param.inputType ? (
                <Button
                  key={idx}
                  style={{
                    fontFamily: "Oxanium",
                    fontSize: ".6rem",
                    // ...buttonStyle,
                    color: param.color ? param.color : "rgb(70,70,70)",
                    backgroundColor: param.bgColor
                      ? param.bgColor
                      : "rgb(200,200,200)",
                  }}
                  onClick={() => {
                    connectModal
                      ? setConnectModal(false)
                      : setConnectModal(true);
                    setParamState(param as ParamType);
                  }}
                >
                  {param.label}
                </Button>
              ) : (
                <Button
                  key={idx}
                  style={{
                    // ...buttonStyle,
                    fontFamily: "Oxanium",
                    fontSize: ".6rem",
                    color: param.color ? param.color : "rgb(70,70,70)",
                    backgroundColor: param.bgColor
                      ? param.bgColor
                      : "rgb(200,200,200)",
                  }}
                  onClick={() => {
                    param.func(param.value);
                    setParamState(param as ParamType);
                  }}
                >
                  {param.label}
                </Button>
              );
            })}
          </Masonry>
          <Button
            type="text"
            style={{ fontFamily: "Oxanium", margin: ".5rem", color: "white" }}
            icon={<ControlOutlined />}
            onClick={() => nav("/tools")}
          />
        </div>
        <Modal
          open={connectModal}
          onCancel={() => setConnectModal(false)}
          footer={null}
        >
          {paramState && (
            <>
              {paramState.param !== "disconnectCam" &&
                paramState.param !== "connect" &&
                paramState.dataType !== "preset" && (
                  <Form form={inputForm}>
                    <Form.Item name="input">
                      <Input
                        placeholder={paramState.label}
                        type={
                          paramState.inputType !== null
                            ? paramState.inputType
                            : "string"
                        }
                      />
                    </Form.Item>
                  </Form>
                )}
              <Button
                style={buttonStyle}
                onClick={() => {
                  inputForm
                    .validateFields()
                    .then((values) => {
                      setValueState(values.input);
                    })
                    .then(() => {
                      paramState.func(paramState.value);
                    });
                }}
              >
                {paramState.label}
              </Button>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};
