import FlvJs from "pro-flv.js";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { windowSize } from "../recoil/recoil";
const md5: any = require("crypto-js/md5");

export const Live = () => {
  const navigate = useNavigate();
  const params = useParams();
  const videoElement = useRef(null);
  const { width } = useRecoilValue(windowSize);

  const expiry = new Date().getTime();

  useEffect(() => {
    if (videoElement.current) {
      //
      const flvPlayer = FlvJs.createPlayer({
        type: "flv",
        url: `http://app.scryptworxstudios.com/live/${
          params.name
        }.flv?sign=${expiry}-${md5(
          `/live/${params.name}-${expiry}-${params.key}`
        )}`,
        isLive: true,
        cors: true,
      });
      flvPlayer.attachMediaElement(videoElement.current);
      flvPlayer.load();
      flvPlayer.play();
      flvPlayer.on("LOADING_COMPLETE", (e) => {
        console.log({ e });
      });
      return () => flvPlayer.destroy();
    }
  }, [expiry, params]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        color: "white",
        fontSize: "1rem",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
      }}
    >
      <div
        style={{ paddingTop: "2rem", paddingBottom: "2rem", cursor: "pointer" }}
        onClick={() => navigate("/opsControl")}
      >
        LOGO
      </div>
      <div style={{ alignSelf: "center" }}>
        <video
          id="videoElement"
          ref={videoElement}
          height={width < 600 ? `${(width / 16) * 9}px` : `${(600 / 16) * 9}px`}
          width={width < 600 ? `${width}px` : `${600}px`}
          controls
        ></video>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        ></div>
      </div>
    </div>
  );
};
