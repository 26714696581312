import { Button, Divider, DropDownProps, Dropdown, MenuProps } from "antd";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { activeProjectState, scriptState } from "../recoil/recoil";
import { EleComp } from "./EleComp";
import { Scene } from "../typeDefs";
import { collection, onSnapshot } from "@firebase/firestore";
import { db } from "../firebase";
import { FileTextOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

export const AIScriptWriter = () => {
  const script = useRecoilValue(scriptState);
  const activePro = useRecoilValue(activeProjectState);
  const [scenes, setScenes] = useState<Array<Scene>>([]);
  const nav = useNavigate();

  useEffect(() => {
    const sceneRef = collection(db, `scripts/${script?.id}/scenes`);

    const unsub = onSnapshot(sceneRef, (snap) => {
      setScenes([]);
      snap.docs.forEach((doc) => {
        setScenes((prev) => [...prev, { ...doc.data(), id: doc.id } as Scene]);
      });
    });

    return unsub;
  }, [script]);

  const handleScenePlanning = ({ id }: { id: string }) => {
    nav(`/scene-planning/${id}`);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        alignItems: "center",
        fontSize: "12pt",
      }}
    >
      <div
        style={{
          color: "black",
          backgroundColor: "white",
          width: "794px",
          paddingBottom: "5rem",
          paddingTop: "3rem",
        }}
      >
        <div
          style={{
            paddingBottom: "2rem",
          }}
        >
          {script?.name}
        </div>
        <div
          style={{
            paddingBottom: "2rem",
          }}
        >
          {script?.authors}
        </div>
        {script && <div>{new Date(script.dateCreated).getFullYear()}</div>}
        <Divider />
        {scenes.length > 0 &&
          script &&
          script?.scriptMap.map((sceneId, idx) => {
            const scene = scenes.filter((item) => item.id === sceneId)[0];
            //scene
            return (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    width: "60%",
                    fontWeight: "bold",
                    fontFamily: "monospace",
                    paddingTop: "2rem",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {`${
                      idx + 1
                    } ${scene.sceneInOut.toUpperCase()}. ${scene.sceneLocation.toUpperCase()} - ${scene.sceneTime.toUpperCase()} `}{" "}
                  </div>
                  <div style={{ paddingLeft: "1rem" }}></div>
                  <Button
                    type="text"
                    icon={<FileTextOutlined />}
                    onClick={() => handleScenePlanning({ id: scene.id })}
                  />
                </div>

                {activePro && (
                  <EleComp
                    sceneId={scene.id}
                    scriptId={activePro.scriptId}
                    sceneMap={scene.elMap}
                  />
                )}
                <Divider />

                <Button type="text" icon={<PlusSquareOutlined />} />
              </div>
            );
          })}
        {/* <SceneEl sceneNumber={sceneNum} /> */}
      </div>
    </div>
  );
};

// authors
// "Phil Shaw + ChatGPT"
// (string)

// dateCreated
// 1705017303255
// (number)

// id
// "G7giAmi5SdzKVM6KKTeTA"
// (string)

// map
// (array)

// 0
// "kUFE4rftFdCjZYArWU2e"
// (string)

// name
// "Hangareka"
// (string)

// projectId
// "MfiOw21X-D9duNf0ql5qQ"
// (string)

// stage
// "First Draft"
// (string)

// type
// "narrative"

//elements
//collection
