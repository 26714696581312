import {
  CopyOutlined,
  InfoCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { Button, Form, Image, Input, message, Modal, Select, Spin } from "antd";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";
import {
  contentCreatorAIState,
  contentCreatorImageState,
} from "../recoil/contentCreatorStates";
import { db, functions } from "../firebase";
import { AudioGen } from "./AudioGen";
import { doc, getDoc } from "firebase/firestore";

const AIAnalyser = () => {
  const [promptForm] = Form.useForm();
  const [isVis, setIsVis] = useState("image");
  const [isWaiting, setIsWaiting] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const [textData, setTextData] = useState<null | string>(null);
  const [percent, setPercent] = useState(-50);
  const [content, setContent] = useRecoilState(contentCreatorAIState);
  const [imageForm] = Form.useForm();
  const [imagePrompt, setImagePrompt] = useRecoilState(
    contentCreatorImageState
  );
  const [imageData, setImageData] = useState<string | null>(null);
  const opt = [
    { value: "OIA", label: "Write an OIA" },
    { value: "Summary", label: "Summarise this text" },
    { value: "Image", label: "Image prompt from text" },
    { value: "Custom", label: "Custom request" },
  ];

  const [socketUrl, setSocketUrl] = useState("");
  useEffect(() => {
    getDoc(doc(db, "socket", "sdfgrerretgrgre"))
      .then((snap) => {
        snap.data()?.auth && setSocketUrl(snap.data()?.auth);
      })
      .catch((err) => {
        message.error("Failed to get socket url");
      });
  }, []);

  const handleSubmit = () => {
    promptForm
      .validateFields()
      .then(async (values) => {
        setIsWaiting(true);
        let refinedPrompt: null | string = null;

        // const req = httpsCallable(functions, "getAIText");

        if (values.selector === "OIA") {
          refinedPrompt = `Write an Official Information Act 1982 request asking for the following information: ${values.prompt}`;
        } else if (values.selector === "Summary") {
          refinedPrompt = `Write a summary of the following text: ${values.prompt}`;
        } else if (values.selector === "Image") {
          refinedPrompt = `Write an image prompt to generate an engaging image that communicates the ideas in the following text: ${values.prompt}`;
        } else {
          refinedPrompt = values.prompt;
        }

        await fetch(`https://api.aimediacommunications.org/gen`, {
          headers: {
            authorization: socketUrl,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt: refinedPrompt, type: "text" }),
          method: "POST",
        })
          // req({ body: { prompt: refinedPrompt } })
          .then(async (res) => {
            const resData = await res.json();
            message.success("Text generated");
            setIsWaiting(false);
            setTextData(resData.text as string);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const downloadImage = (dataUrl: string) => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "Image";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getImage = () => {
    setIsWaiting(true);
    imageForm
      .validateFields()
      .then(async (values) => {
        await fetch(`https://api.aimediacommunications.org/gen`, {
          headers: {
            authorization: socketUrl,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prompt: values.prompt, type: "image" }),
          method: "POST",
        })
          // req({ body: { prompt: refinedPrompt } })
          .then(async (res) => {
            const resData = await res.json();
            message.success("Image generated");
            setIsWaiting(false);
            setImageData(`data:image/png;base64,${resData.image}`);
          })
          .catch((err) => console.error(err));

        // const req = httpsCallable(functions, "getAIImage");
        // req({ body: { prompt: values.prompt } })
        //   .then((res) => {
        //     message.success("Image generated");
        //     setIsWaiting(false);
        //     setImageData(`data:image/png;base64,${res.data}`);
        //     //console.log(res.data);
        //   })
        //   .catch((err) => console.error(err));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPercent((v) => {
        const nextPercent = v + 5;
        return nextPercent > 150 ? -50 : nextPercent;
      });
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
        padding: "5vw",
      }}
    >
      <div
        style={{
          padding: "1rem",
          display: "flex",
          gap: "1rem",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Button icon={<InfoCircleOutlined />} onClick={() => setIsInfo(true)} />
        <Button onClick={() => setIsVis("image")}>Image</Button>
        <Button onClick={() => setIsVis("audio")}>Audio</Button>
        <Button onClick={() => setIsVis("text")}>Text</Button>
      </div>
      {isVis === "image" && (
        <div
          style={{
            width: "100%",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <div style={{ width: "50%" }}>
            <Form
              style={{ width: "100%" }}
              form={imageForm}
              initialValues={{ prompt: imagePrompt && imagePrompt }}
            >
              <Form.Item
                name="prompt"
                rules={[{ required: true, message: "You must have a prompt" }]}
              >
                <Input.TextArea
                  style={{ width: "100%" }}
                  rows={15}
                  placeholder="Image prompt"
                  onChange={() =>
                    setImagePrompt(() => imageForm.getFieldValue("prompt"))
                  }
                />
              </Form.Item>
            </Form>
            <Button disabled={isWaiting} onClick={() => getImage()}>
              Get Image
            </Button>
          </div>

          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {imageData && (
              <Image src={imageData} onClick={() => downloadImage(imageData)} />
            )}
            {isWaiting && (
              <div
                style={{ width: "50%", textAlign: "center", padding: "1rem" }}
              >
                <Spin size="large" />
                <div style={{ color: "#70A0FF" }}>Please wait...</div>
              </div>
            )}
          </div>
        </div>
      )}
      {isVis === "audio" && <AudioGen />}
      {isVis === "text" && (
        <div>
          <div style={{ padding: "2rem" }}>
            <Form
              form={promptForm}
              initialValues={{ prompt: content && content }}
            >
              <Form.Item name="selector">
                <Select options={opt} placeholder="Select prompt type" />
              </Form.Item>
              <Form.Item name="prompt">
                <Input.TextArea
                  onChange={(e) =>
                    setContent(() => promptForm.getFieldValue("prompt"))
                  }
                  rows={10}
                  placeholder="Write your prompt"
                ></Input.TextArea>
              </Form.Item>
            </Form>
            {content && <div>{content.split(" ").length - 1} words</div>}
            <Button
              disabled={isWaiting}
              icon={<SendOutlined />}
              onClick={() => handleSubmit()}
            />
            {isWaiting && (
              <div
                style={{ width: "100%", textAlign: "center", padding: "1rem" }}
              >
                <Spin size="large" />
                <div style={{ color: "#70A0FF" }}>Please wait...</div>
              </div>
            )}
            {textData && (
              <div>
                <div>Result</div>
                <div
                  style={{
                    display: "block",
                    whiteSpace: "pre-wrap",
                    border: "1px solid rgb(135,135,135)",
                    //padding: ".5rem",
                    borderRadius: ".25rem",
                  }}
                >
                  <MarkdownEditor
                    value={textData}
                    style={{
                      maxWidth: "800px",
                      lineBreak: "anywhere",
                      whiteSpace: "pre-wrap",
                    }}
                    width="500px"
                    // onChange={(value, viewUpdate) => {
                    //   console.log(value, viewUpdate);
                    // }}
                  />
                </div>
                <Button
                  onClick={() => navigator.clipboard.writeText(textData)}
                  icon={<CopyOutlined />}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        open={isInfo}
        onCancel={() => setIsInfo(false)}
        footer={null}
        title="Using AI Assist"
      >
        <div>AI Assist uses ChatGPT under-the-hood.</div>
        <div style={{ paddingTop: "1rem" }}>
          There are four options selectable to help speed up requests that will
          preface the requests with the following text:
        </div>

        <div
          style={{
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <ul>
            <li style={{ fontWeight: "bold" }}>OIA</li>
            <div>
              Write an Official Information Act 1982 request asking for the
              following information: ...
            </div>
            <li style={{ fontWeight: "bold" }}>Summary</li>
            <div>Write a summary of the following text: ...</div>
            <li style={{ fontWeight: "bold" }}>Image</li>
            <div>
              Write an image prompt to generate an engaging image that
              communicates the ideas in the following text: ...
            </div>
            <li style={{ fontWeight: "bold" }}>Custom request</li>
            <div>There is no predefined text in the custom request</div>
          </ul>
        </div>
      </Modal>
    </div>
  );
};
export default AIAnalyser;
