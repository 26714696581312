import { useEffect, useRef, useState } from "react";
import { AudioGraph } from "./AudioGraph";
import { message } from "antd";

export const AudioAnalyser = () => {
  const [data, setData] = useState<Uint8Array>(new Uint8Array());
  const dataArray = useRef<Uint8Array>(new Uint8Array());
  const audioContext = useRef<AudioContext | null>(null);
  //let audioContext: AudioContext | null = null;
  const analyser = useRef<AnalyserNode | null>(null); //= useState<AnalyserNode|null>(null);
  let source: MediaStreamAudioSourceNode | null = null;

  const draw = () => {
    analyser.current?.getByteTimeDomainData(dataArray.current);
    setData(() => new Uint8Array(dataArray.current));
    //console.log(dataArray);
    requestAnimationFrame(draw);
  };

  const handleSuccess = (stream: MediaStream) => {
    audioContext.current = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    analyser.current = audioContext.current.createAnalyser();
    source = audioContext.current.createMediaStreamSource(stream);
    analyser && source.connect(analyser.current);
    analyser.current.fftSize = 2048;
    const bufferLength = analyser.current.frequencyBinCount;
    dataArray.current = new Uint8Array(bufferLength);

    draw();
  };

  useEffect(() => {
    if (!navigator.mediaDevices) {
      message.error("Your browser does not support media devices.");
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => handleSuccess(stream))
      .catch((err) => message.error("Error accessing microphone"));

    return () => {
      source?.disconnect();
      analyser.current?.disconnect();
      audioContext.current && audioContext.current.close();
    };
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh", zIndex: "14" }}>
      {data && audioContext.current && <AudioGraph data={data} />}
    </div>
  );
};
