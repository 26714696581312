import {
  EditOutlined,
  InfoCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Popover } from "antd";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { FinanceEntryType } from "../typeDefs";
import { useEffect, useState } from "react";
(window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const PrintFinances = ({
  data,
  dates,
}: {
  data: Array<FinanceEntryType>;
  dates: Array<number>;
}) => {
  const [summaryData, setSummaryData] = useState<Array<any>>([]);

  const calculateBookValue = (
    originalValue: number,
    purchaseDate: number,
    depreciationRate: number
  ) => {
    // Parse the purchase date and calculate the number of years since purchase
    const purchaseYear = new Date(purchaseDate).getFullYear();
    const currentYear = new Date().getFullYear();
    const yearsElapsed = currentYear - purchaseYear;

    // Calculate the annual depreciation amount
    const annualDepreciation = (originalValue * depreciationRate) / 100;

    // Calculate the total depreciation over the elapsed years
    const totalDepreciation = annualDepreciation * yearsElapsed;

    // Calculate the book value
    const bookValue = originalValue - totalDepreciation;

    // Ensure the book value doesn't go below zero
    return Math.max(bookValue, 0);
  };

  useEffect(() => {
    setSummaryData([]);
    let gstOut = 0;
    let gstIn = 0;
    let gstBalance = 0;
    let expenses = 0;
    let revenue = 0;
    let purchases = 0; // gst balance
    let profits = 0;
    data
      .filter((item) => item.date >= dates[0] && item.date < dates[1])
      .filter((item) => item.category === "Revenue")
      .filter((item) => item.date >= new Date("2024-05-01").getTime())
      .forEach((item) => {
        gstOut = gstOut + item.amount * 0.15;
      });

    data
      .filter((item) => item.date >= dates[0] && item.date < dates[1])
      .filter((item) => item.category === "Assets")
      .forEach((item) => {
        gstIn = gstIn + item.amount * 0.15;
      });

    data
      .filter((item) => item.date >= dates[0] && item.date < dates[1])
      .filter((item) => item.category === "Expenses")
      .forEach((item) => {
        expenses = expenses + item.amount;
      });

    data
      .filter((item) => item.date >= dates[0] && item.date < dates[1])
      .filter((item) => item.category === "Revenue")
      .forEach((item) => {
        revenue = revenue + item.amount;
      });

    data
      .filter((item) => item.date >= dates[0] && item.date < dates[1])
      .filter((item) => item.category === "Assets")
      .forEach((item) => {
        purchases = purchases + item.amount;
      });

    gstBalance = gstIn - gstOut;

    setSummaryData([
      {
        text: "Total revenue",
        amount: `$${revenue.toFixed(2)}`,
      },
      {
        text: "Total expenses",
        amount: `$(${expenses.toFixed(2)})`,
      },
      {
        text: "Total asset purchases",
        amount: `$(${purchases.toFixed(2)})`,
      },
      {
        text: "Total purchases",
        amount: `$(${(purchases + expenses).toFixed(2)})`,
      },
      {
        text: "Total Profits",
        amount:
          revenue - (purchases + expenses) > 0
            ? `$${(revenue - (purchases + expenses)).toFixed(2)}`
            : `$(${Math.abs(revenue - (purchases + expenses)).toFixed(2)})`,
      },

      {
        text: "GST to claim",
        amount: `$${gstIn.toFixed(2)}`,
      },
      {
        text: "GST owing",
        amount: `$(${gstOut.toFixed(2)})`,
      },
      {
        text: "GST balance",
        amount:
          gstBalance < 0
            ? `$(${gstBalance.toFixed(2)})`
            : `$${gstBalance.toFixed(2)}`,
      },
    ]);
  }, [data, dates]);

  useEffect(() => {}, []);
  const printRecord = (
    data: Array<FinanceEntryType>,
    summary: Array<any>,
    dates: Array<number>
  ) => {
    // const content: Content = [{ text: "Test" }];
    var docDefinition: TDocumentDefinitions = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      content: [
        { text: "SCRYPTWORX STUDIOS FINANCES", style: "header" },
        {
          text: `Date printed: ${new Date().toLocaleDateString()}  \n \n`,
          style: "small",
          color: "#8c8c8c",
          bold: true,
        },
        {
          text: `${new Date(dates[0]).toLocaleDateString()} to ${new Date(
            dates[1]
          ).toLocaleDateString()}  \n \n`,
          style: "small",
          color: "#8c8c8c",
          bold: true,
        },
        {
          text: `Revenue`,
          style: "subheader",
          bold: true,
        },
        {
          style: "tableExample",
          table: {
            //widths: ["200", "300", "200", "auto", "auto", "auto", "auto"],
            body: [
              [
                {
                  text: "Date",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Description",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Category",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Vendor",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST Number",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Amount",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
              ],
              ...data
                .filter((item) => !item.isDeleted)
                .filter((item) => item.date >= dates[0] && item.date < dates[1])
                .filter((item) => item.category === "Revenue")
                .sort((a, b) => (a.date < b.date ? -1 : 1))
                .map((item) => {
                  return [
                    {
                      text: new Date(item.date).toLocaleDateString("en-GB"),
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.description,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.category,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.vendor,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.gstNumber,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text:
                        item.gstNumber && item.gstNumber.length > 6
                          ? `$(${(item.amount * 0.15).toFixed(2)})`
                          : "-",
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: `$${item.amount.toFixed(2)}`,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                  ];
                }),
              [
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "TOTAL",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: `$${data
                    .filter((item) => !item.isDeleted)
                    .filter(
                      (item) => item.date >= dates[0] && item.date < dates[1]
                    )
                    .filter((item) => item.category === "Revenue")
                    .filter(
                      (item) => item.gstNumber && item.gstNumber.length > 6
                    )
                    .map((item) => item.amount * 0.15)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}`,
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: `$${data
                    .filter((item) => !item.isDeleted)
                    .filter(
                      (item) => item.date >= dates[0] && item.date < dates[1]
                    )
                    .filter((item) => item.category === "Revenue")
                    .map((item) => item.amount)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}`,
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
              ],
            ],
            headerRows: 1,
          },
        },
        {
          text: `\n \n Assets`,
          style: "subheader",
          bold: true,
        },
        {
          style: "tableExample",
          table: {
            body: [
              [
                {
                  text: "Date",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Description",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Category",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Vendor",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST Number",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },

                {
                  text: "GST Method",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST Rate",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST Claim",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Depreciation",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },

                {
                  text: "Value",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Book Value",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
              ],
              ...data
                .filter((item) => !item.isDeleted)
                .filter((item) => item.date >= dates[0] && item.date < dates[1])
                .filter((item) => item.category === "Assets")
                .sort((a, b) => (a.date < b.date ? -1 : 1))
                .map((item) => {
                  return [
                    {
                      text: new Date(item.date).toLocaleDateString("en-GB"),
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.description ? item.description : "",
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.category ? item.category : "",
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.vendor ? item.vendor : "",
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.gstNumber ? item.gstNumber : "",
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: (item.amount * 0.15).toFixed(2),
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },

                    {
                      text: item.apportionMethod ? item.apportionMethod : "",
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: `${item.apportionRate ? item.apportionRate : 0}%`,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text:
                        item.apportionRate && item.apportionMethod
                          ? `$${(
                              (item.amount * 0.15 * item.apportionRate) /
                              100
                            ).toFixed(2)}`
                          : "",
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.depreciationRate
                        ? `${
                            item.depreciationMethod === "Straight Line"
                              ? "SL"
                              : "DV"
                          } ${item.depreciationRate}% $${(
                            ((item.amount - item.amount * 0.15) *
                              item.depreciationRate) /
                            100
                          ).toFixed(2)}`
                        : "",
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: `$${item.amount.toFixed(2)}`,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text:
                        item.depreciationRate &&
                        `$${calculateBookValue(
                          item.amount,
                          item.date,
                          item.depreciationRate
                        ).toFixed(2)}`,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                  ];
                }),
              [
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "GST Claim",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: `$${data
                    .filter((item) => !item.isDeleted)
                    .filter(
                      (item) => item.date >= dates[0] && item.date < dates[1]
                    )
                    .filter((item) => item.category === "Assets")
                    .map((item) => {
                      if (item.apportionMethod === "None") return 0;
                      if (!item.apportionRate) return item.amount * 0.15;
                      return (item.amount * 0.15 * item.apportionRate) / 100;
                    })
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}`,

                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "TOTALS",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },

                {
                  text: `$${data
                    .filter((item) => !item.isDeleted)
                    .filter(
                      (item) => item.date >= dates[0] && item.date < dates[1]
                    )
                    .filter((item) => item.category === "Assets")
                    .map((item) => item.amount)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}`,
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: `$${data
                    .filter((item) => !item.isDeleted)
                    .filter(
                      (item) => item.date >= dates[0] && item.date < dates[1]
                    )
                    .filter((item) => item.category === "Assets")
                    .map((item) => {
                      if (!item.depreciationRate) return item.amount;
                      return calculateBookValue(
                        item.amount,
                        item.date,
                        item.depreciationRate
                      );
                    })
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)}`,

                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
              ],
            ],
            headerRows: 1,
          },
        },
        {
          text: `\n \n Expenses`,
          style: "subheader",
          bold: true,
        },
        {
          style: "tableExample",
          table: {
            body: [
              [
                {
                  text: "Date",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Description",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Category",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Vendor",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST Number",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Amount",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
              ],
              ...data
                .filter((item) => !item.isDeleted)
                .filter((item) => item.date >= dates[0] && item.date < dates[1])
                .filter(
                  (item) =>
                    item.category === "Expenses" && item.description !== "Draw"
                )
                .sort((a, b) => (a.date < b.date ? -1 : 1))
                .map((item) => {
                  return [
                    {
                      text: new Date(item.date).toLocaleDateString("en-GB"),
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.description,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.category,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.vendor,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.gstNumber,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: (item.amount * 0.15).toFixed(2),
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: `$(${item.amount.toFixed(2)})`,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                  ];
                }),
              [
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "TOTAL",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: `$(${data
                    .filter((item) => !item.isDeleted)
                    .filter(
                      (item) => item.date >= dates[0] && item.date < dates[1]
                    )
                    .filter(
                      (item) =>
                        item.category === "Expenses" &&
                        item.description !== "Draw"
                    )
                    .map((item) => item.amount)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)})`,
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
              ],
            ],
            headerRows: 1,
          },
        },
        {
          text: `\n \n Salaries & Wages Expense`,
          style: "subheader",
          bold: true,
        },
        {
          style: "tableExample",
          columnGap: 50,
          table: {
            body: [
              [
                {
                  text: "Date",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Description",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Category",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Payee",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST Number",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "GST",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Amount",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
              ],
              ...data
                .filter((item) => !item.isDeleted)
                .filter((item) => item.date >= dates[0] && item.date < dates[1])
                .filter(
                  (item) =>
                    item.category === "Expenses" && item.description === "Draw"
                )
                .sort((a, b) => (a.date < b.date ? -1 : 1))
                .map((item) => {
                  return [
                    {
                      text: new Date(item.date).toLocaleDateString("en-GB"),
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.description,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.category,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.vendor,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: item.gstNumber,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: (item.amount * 0.15).toFixed(2),
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                    {
                      text: `$(${item.amount.toFixed(2)}`,
                      style: "small",
                      borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                    },
                  ];
                }),
              [
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "TOTAL",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: `$(${data
                    .filter((item) => !item.isDeleted)
                    .filter(
                      (item) => item.date >= dates[0] && item.date < dates[1]
                    )
                    .filter(
                      (item) =>
                        item.category === "Expenses" &&
                        item.description === "Draw"
                    )
                    .map((item) => item.amount)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)})`,
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
              ],
            ],
            headerRows: 1,
          },
        },
        {
          text: `\n \n Summary`,
          style: "subheader",
          bold: true,
        },
        {
          style: "tableExample",
          table: {
            body: [
              [
                {
                  text: "Type",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
                {
                  text: "Amount",
                  bold: true,
                  borderColor: ["#ffffff", "#ffffff", "#ffffff", "#8c8c8c"],
                },
              ],
              ...summaryData.map((item) => {
                return [
                  {
                    text: item.text,
                    style: "small",
                    borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                  },
                  {
                    text: item.amount,
                    style: "small",
                    borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                  },
                ];
              }),
              [
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
                {
                  text: "",
                  style: "small",
                  borderColor: ["#ffffff", "#dedede", "#ffffff", "#dedede"],
                },
              ],
            ],
            headerRows: 1,
          },
        },
        {
          layout: "lightHorizontalLines", // optional
          table: {
            headerRows: 1,
            widths: [250, 200, "*"],
            body: [
              [
                {
                  text: "Business name: SCRYPTWORX STUDIOS LIMITED.",
                  style: "small",
                },
                {
                  text: `Account number: 12-3616-0018990-00`,
                  style: "small",
                },
              ],
            ],
          },
        },
      ],
      info: {
        title: "SCRYPTWORX STUDIOS FINANCES",
        author: "scryptworxstudios.com",
        subject: "Financial information",
        keywords: "Finances",
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          color: "#1e90ff",
        },
        subheader: {
          fontSize: 12,
          bold: true,
        },
        quote: {
          italics: true,
        },
        small: {
          fontSize: 10,
        },
        tableExample: {
          margin: [0, 5, 0, 5],
        },
      },
    };
    pdfMake.createPdf(docDefinition).open();
  };

  return (
    <div>
      <Button
        type="text"
        icon={<PrinterOutlined />}
        onClick={() => printRecord(data, summaryData, dates)}
      />
    </div>
  );
};
