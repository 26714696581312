import { useEffect, useRef, useState } from "react";
import { AudioVisualizer } from "react-audio-visualize";

import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";

export const AudioVisual = ({ fileUrl }: { fileUrl: string }) => {
  const [blob, setBlob] = useState<Blob>();
  const visualizerRef = useRef<HTMLCanvasElement>(null);
  const [fileLocation, setFileLocation] = useState<string>("");

  useEffect(() => {
    const fetchBlob = async () => {
      const mp3FileRef = ref(storage, fileUrl as string);

      // Get the download URL
      getDownloadURL(mp3FileRef)
        .then(async (url) => {
          setFileLocation(url);
          const response = await fetch(url, { method: "GET", mode: "no-cors" });
          console.log(url, response);
          const blob = await response.blob();
          console.log(blob);
          if (blob.size > 0) {
            setBlob(blob);
          }
        })
        .catch((error) => {
          console.error("Error fetching MP3 file:", error);
        });
    };
    fetchBlob();
  }, [fileUrl]);

  return (
    <div>
      <audio controls src={fileLocation} style={{ maxHeight: "40px" }} />
      {blob && (
        <AudioVisualizer
          ref={visualizerRef}
          blob={blob}
          width={500}
          height={75}
          barWidth={1}
          gap={0}
          barColor={"#f76565"}
        />
      )}
    </div>
  );
};
